<template>
  <!-- <div class="machineFormLayer" v-if="machineDisplay"> -->
  <!--<div id="machine-information" v-if="machineDisplay && !submitted">-->
  <div class="tabsDescr" v-if="machineDisplay && !submitted">
    <div
      class="tab active"
      @click="setActiveTab(1)"
      v-html="this.$t(`machineInformation.tab1`)"
    ></div>
    <div
      class="tab"
      @click="setActiveTab(2)"
      v-html="this.$t(`machineInformation.tab2`)"
    ></div>
    <div
      class="tab"
      @click="setActiveTab(3)"
      v-html="this.$t(`machineInformation.tab3`)"
    ></div>
  </div>
  <div class="machineFormLayer" v-if="machineDisplay && !submitted">
    <div
      id="machine-information"
      class="tabcontent"
      v-bind:class="activeMachine ? 'active' : ''"
      v-if="!submitted"
    >
      <div id="machine-type-selector">
        <p v-html="$t('machineInformation.typeHeadline')"></p>
        <div id="machine-types">
          <label class="machine-type">
            {{ coffeeMachine.name }}
            <input
              type="radio"
              name="machine-type"
              id="Delizio"
              compact=""
              one=""
              ii=""
              value="Delizio"
              checked=""
            />
            <span
              class="checkmark"
              for="Delizio"
              compact=""
              one=""
              ii=""
            ></span>
          </label>
        </div>
        <p class="warning" id="machineNameWarning" style="display: none;">
          Bitte wählen Sie den Maschinennamen.^
        </p>
      </div>
      <div
        id="machine-image-wrapper"
        class="machine-image"
        v-if="machineImg.display"
      >
        <img
          id="machine-image"
          :src="'/assets/image/machineimages/' + machineImg.img"
          alt="machine-image"
        />
      </div>
      <div
        id="machine-color-selector"
        class="machine-color-selector"
        style="display: block;"
      >
        <p v-html="$t('machineInformation.colorHeadline')"></p>
        <div id="color-selector-variants" style="display: flex">
          <div
            class="first"
            @click="
              selectMachineColor(
                $event,
                index,
                type.colorCode,
                type.colorName,
                type.image
              )
            "
            v-for="(type, index) in coffeeMachine.types"
            :key="index"
            @mouseenter="showToolTip($event)"
            @mouseleave="hideToolTip($event)"
          >
            <span class="tooltiptext">
              {{ type.colorName }}
            </span>
            <div class="second">
              <div class="third" :style="{ background: type.colorCode }"></div>
            </div>
          </div>
        </div>
        <p class="warning" id="colorWarning" style="display: none;">
          Bitte wählen Sie die Farbe.
        </p>
      </div>
      <!-- <div>
        <p v-html="$t('machineInformation.TeilnahmecodeHeadline')"></p>
        <input type="text" name="teilnahmecode"  />
      </div> -->
      <!-- <div class="date-wrapper" id="date-input-wrapper" v-if="buyDate.display">
        <p v-html="$t('machineInformation.buyDateHeadline')"></p>
        <div class="date-flex">
          <input
            type="date"
            id="buy-date"
            name="buy-date"
            placeholder="tt/mm/jjjj"
            min="2021-01-01"
            v-model="date"
          />
          <p class="warning" id="date-warning" style="display: none;">
            Bitte beachten Sie die Reihenfolge TT.MM.JJ
          </p>
          <p class="warning" id="date-warning" v-if="buyDate.invalid">
            {{ $t("machineInformation.dateError") }}
          </p>
        </div>
      </div> -->
      <div
        id="machine-purchase-store-locations"
        class="machine-purchase-store-locations"
      >
        <p v-html="$t('machineInformation.storeHeadline')"></p>
        <div class="row">
          <div class="col-lg-12 col-md-12 col-sm-12">
            <button
              class="location"
              @click="selectStore($event)"
              v-for="(location, index) in Object.keys(
                $i18n.messages[$i18n.locale].machineInformation.stores
              )"
              :key="index"
              v-html="this.$t(`machineInformation.stores[${location}]`)"
            ></button>
          </div>
        </div>
        <p
          class="warning"
          id="sellerWarning"
          v-if="noSeller"
          v-html="this.$t(`machineInformation.sellermessage`)"
        ></p>
      </div>
      <div class="columns c-12 m-6 no-padding pt-2">
        <div class="row">
          <div
            class="ui-form-item columns c-6 no-right-padding"
            :class="{
              hasError: teilnamecodeStatus == 1,
              hasSuccess: teilnamecodeStatus == 2,
            }"
          >
            <label
              for="name"
              v-html="$t('machineInformation.TeilnahmecodeHeadline')"
            ></label>
            <div class="infoTooltip">
              <img
                src="assets/image/icons/icon-info.png"
                @click="activateTooltip()"
              />
              <span
                class="tooltiptext"
                v-html="this.$t(`machineInformation.Fleyermessage`)"
              ></span>
            </div>
            <input
              type="text"
              name="teilnahmecode"
              id="teilnahmecode"
              :placeholder="$t('machineInformation.TeilnahmecodeHeadline')"
              v-on:blur="checkCode($event)"
            />
            <div
              class="input-errors"
              v-for="(error, index) of v$.form.name.$errors"
              :key="index"
            >
              <div class="error-msg">{{ error.$message }}</div>
            </div>
          </div>
        </div>
      </div>
      <div
        id="registration-section"
        class="registration-section"
        style="display: block;"
      ></div>
    </div>
    <AddressForm :display="activeAddress" ref="addressFormRef" />
    <ProductView
      :display="activeProducts"
      ref="productRef"
      @update="onProductUpdate"
    />
    <div class="migros-registration" v-if="!submitted">
      <p class="warning" id="errorAllreadySubmitted" v-if="alreadyRegistered">
        {{ $t("machineInformation.alreadyRegistered") }}
      </p>
      <p class="warning" id="general-error" style="display: none;">
        Interner Serverfehler, versuchen Sie es später erneut
      </p>
      <button
        id="submit-button"
        @click="goBack()"
        v-if="activeTab !== 1"
        style="float:left"
      >
        <div id="loader" class="loader" v-if="buttonLoader"></div>
        <span
          id="submit-text"
          v-html="$t('machineInformation.prevButton')"
        ></span>
      </button>
      <button id="submit-button" @click="formAction()" style="float:right">
        <div id="loader" class="loader" v-if="buttonLoader"></div>
        <span
          id="submit-text"
          v-if="!buttonLoader && activeTab === 3"
          v-html="$t('machineInformation.submitButton')"
        ></span>
        <span
          id="submit-text"
          v-if="!buttonLoader && activeTab !== 3"
          v-html="$t('machineInformation.nextButton')"
        ></span>
      </button>
    </div>
  </div>
  <hr v-if="machineDisplay && !submitted" />
  <div class="formSubmitted" v-if="submitted">
    <div class="row">
      <div class="columns c-12">
        <div class="ui-form-success-message">
          <p class="h2">
            <strong v-html="$t('formSubmittedSuccessBlock.headline')"></strong>
          </p>
          <p v-html="$t('formSubmittedSuccessBlock.text1')"></p>
          <p style="margin-bottom:0;">
            <strong v-html="$t('formSubmittedSuccessBlock.text2')"></strong>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ProductView from "./ProductView.vue";
import AddressForm from "./AddressForm.vue";
import useVuelidate from "@vuelidate/core";
import { required, email } from "../utils/i18n-validators";

export default {
  name: "MachineInformation",
  props: {
    display: Boolean,
    machineData: String,
    serialNumber: String,
  },
  components: {
    ProductView,
    AddressForm,
  },
  setup() {
    return { v$: useVuelidate() };
  },
  updated() {
    this.$nextTick(function() {
      if (this.data) {
        // this.coffeeMachine.types = []
        this.coffeeMachine.name = this.data.name;
        this.coffeeMachine.types = this.data.machineVariant;
        // this.machineImg.img = this.data.machineVariant.types[0].image;
        // let firstKey = Object.keys(this.data.models)[0];
        // this.coffeeMachine.name = this.data.models[firstKey].name;
        // for (
        //   let i = 0;
        //   i < Object.keys(this.data.models[firstKey].colors).length;
        //   i++
        // ) {
        //   const colorKey = Object.keys(this.data.models[firstKey].colors)[i];
        //   const element = this.data.models[firstKey].colors[colorKey];
        //   this.coffeeMachine.types[i] = element;
        // }
      }
      if (this.firstload) {
        if (document.querySelector(".first")) {
          let allElements = document.querySelectorAll(".first");
          // console.log(allElements);
          allElements.forEach((element) => {
            // console.log(element);
            element.classList.remove("selected");
          });
          allElements[0].classList.add("selected");
          this.firstload = false;
        }
      }
    });
  },
  data() {
    return {
      machineDisplay: this.display || false,
      activeMachine: true,
      activeProducts: false,
      activeAddress: false,
      data: this.machineData,
      buttonLoader: false,
      noSeller: false,
      firstload: true,
      submitted: false, //false,
      alreadyRegistered: false,
      activeTab: 1,
      teilnamecodeStatus: 0,
      noTeilnahmecode: false,
      addressFormRef: null,
      productRef: null,
      teilnamecode: null,
      coffeeMachine: {
        name: "",
        types: [],
      },
      machineImg: {
        img: "",
        display: false,
      },
      buyDate: {
        date: "",
        display: false,
        invalid: false,
      },
      stores: {
        display: false,
        value: "",
      },
      contactForm: {
        display: false,
      },
      date: "",
      color: "",
      form: {
        gender: "",
        firstname: "",
        name: "",
        email: "",
        checkTerms: "",
      },
      usedSerial: this.serialNumber,
      products: null,
    };
  },
  watch: {
    // date() {
    //   // console.log(val);

    //   this.selectDate();
    //   // this.date = val
    // },
    display(val) {
      // console.log(val);
      this.machineDisplay = val;
    },
    machineData(val) {
      this.resetData();
      // this.data = JSON.parse(val);
      this.data = val;
      this.machineImg.img = this.data.machineVariant[0].image;
      this.color = this.data.machineVariant[0].colorName;
      this.machineImg.display = true;
      // this.buyDate.display = true;
    },
    serialNumber(val) {
      this.usedSerial = val;
    },
  },
  validations() {
    return {
      form: {
        gender: {
          required,
        },
        firstname: {
          required,
        },
        name: {
          required,
        },
        email: {
          required,
          email,
        },
        checkTerms: {
          required,
        },
      },
    };
  },
  methods: {
    onProductUpdate(products) {
      this.products = JSON.parse(products);
      console.log('event');
      console.log(this.products);
    },
    checkCode(e) {
      let code = e.target.value;
      let codeform = new FormData();
      codeform.append("code", code);
      fetch(this.apiUrl + "api/checkCode", {
        method: "POST",
        mode: "cors",
        body: codeform,
      })
        .then((response) => response.json())
        .then((response) => {
          if (response.statusCode == 10000) {
            this.teilnamecodeStatus = 2;
            this.teilnamecode = code;
          } else {
            this.teilnamecodeStatus = 1;
          }
        })
        .catch((e) => {
          console.log(e);
          this.teilnamecodeStatus = 1;
        });
    },
    setActiveTab(i, prev = false) {
      if (i < this.activeTab) prev = true;
      if (!prev) {
        if (this.teilnamecodeStatus != 2 || this.stores.value == "") {
          if (this.stores.value == "") {
            this.noSeller = true;
          }
          return;
        }
        this.noSeller = false;
        if (this.activeTab - 1 == 1) {
          if (this.$refs.productRef.productTotal != 2) {
            return;
          }
        }
        if (this.activeTab - 1 == 2) {
          if (!this.$refs.addressFormRef.validateForm()) {
            return;
          }
        }
      }
      this.activeTab = i;

      switch (i) {
        case 1:
          this.activeProducts = false;
          this.activeAddress = false;
          this.activeMachine = true;
          break;
        case 2:
          this.activeProducts = true;
          this.activeAddress = false;
          this.activeMachine = false;
          break;
        case 3:
          this.activeProducts = false;
          this.activeAddress = true;
          this.activeMachine = false;
          break;

        default:
          break;
      }
      document.querySelectorAll(".tab").forEach((e) => {
        e.classList.remove("active");
      });
      document.querySelectorAll(".tab")[i - 1].classList.add("active");
      // e.target.classList.add("active");
    },
    selectMachineColor(currentElement, arrayID, colorcode, name, img) {
      let allElements = document.querySelectorAll(".first");

      this.machineImg.img = img;
      this.machineImg.display = true;
      this.color = name;

      allElements.forEach((element) => {
        element.classList.remove("selected");
      });
      allElements[arrayID].classList.add("selected");
      // this.buyDate.display = true;
    },
    // selectDate() {
    //   let dateForm = new FormData();
    //   let date = Math.floor(new Date(this.date).getTime() / 1000);
    //   dateForm.append("date", date);
    //   fetch(this.apiUrl + "api/checkDate", {
    //     method: "POST",
    //     body: dateForm,
    //   })
    //     .then((response) => response.json())
    //     .then((response) => {
    //       if (response.data) {
    //         this.stores.display = true;
    //         this.buyDate.invalid = false;
    //       } else {
    //         this.stores.display = false;
    //         this.buyDate.invalid = true;
    //         this.contactForm.display = false;
    //       }
    //       // console.log(response);
    //     });
    // },
    selectStore(currentElement) {
      let allElements = document.querySelectorAll(".location");
      this.contactForm.display = true;
      allElements.forEach((element) => {
        element.classList.remove("selected");
      });
      currentElement.target.classList.add("selected");
      this.stores.value = currentElement.target.innerHTML;
    },
    showToolTip(event) {
      event.target.closest(".first").firstChild.style = "visibility:visible";
    },
    hideToolTip(event) {
      event.target.closest(".first").firstChild.style = "visibility:hidden";
    },
    goBack() {
      if (this.activeTab != 1) {
        this.setActiveTab(this.activeTab - 1, true);
      }
    },
    formAction() {
      if (this.activeTab === 3) {
        this.submitForm();
      } else {
        this.setActiveTab(this.activeTab + 1);
      }
    },
    submitForm() {
      this.buttonLoader = true;
      setTimeout(() => {
        // this.v$.form.$touch();
        if (!this.$refs.addressFormRef.validateForm()) {
          this.buttonLoader = false;
          return;
        }

        let registerForm = document.getElementById("contactData");
        let formData = new FormData(registerForm);
        formData.append("language", this.$i18n.locale);
        formData.append("color", this.color);
        formData.append("market", this.stores.value);
        formData.append("serial", this.usedSerial);
        formData.append("teilnahmecode", this.teilnamecode);
        for (const [key, value] of Object.entries(this.products)) {
          formData.append(key, value);
        }

        fetch(this.apiUrl + "api/formcommit", {
          method: "POST",
          mode: "cors",
          body: formData,
        })
          .then((response) => {
            if (response.status == 400) {
              console.log("validation error");
              this.buttonLoader = false;
              return;
            }
            if (response.status == 480) {
              // console.log("serial already exists");
              this.alreadyRegistered = true;
              this.emailExists = false;
              this.buttonLoader = false;
              return;
            }
            if (response.status == 409) {
              // console.log("E-Mail already exists");
              this.emailExists = true;
              this.alreadyRegistered = false;
              this.buttonLoader = false;
              return;
            }
            if (response.status == 201) {
              // console.log("User created");
              this.submitted = true;
              this.alreadyRegistered = false;
              this.emailExists = false;
              this.buttonLoader = false;
              return;
            }
          })
          .catch((e) => console.log(e));

        //DUMMY
        // this.buttonLoader = false;
        // this.submitted = true;
        // console.log(
        //   `Current Serial = ${this.usedSerial} \nColor = ${this.color} \nVorname = ${this.form.firstname} \nNachname = ${this.form.name} \nE-Mail = ${this.form.email} \n`
        // );
      }, 1000);
    },
    resetData() {
      this.coffeeMachine.types = [];
      this.alreadyRegistered = false;
      this.contactForm.display = false;
      // this.stores.display = false;
      // this.buyDate.display = false;
      this.machineImg.img = "";
      this.machineImg.display = false;
      this.submitted = false;
      this.date = "";
      // if(this.form){
      this.form.name = "";
      this.form.firstname = "";
      this.form.email = "";
      // }
    },
    activateTooltip() {
      document
        .querySelector(".infoTooltip .tooltiptext")
        .classList.add("active");
      setTimeout(() => {
        document
          .querySelector(".infoTooltip .tooltiptext")
          .classList.remove("active");
      }, 3000);
    },
  },
};
</script>

<style>
.tabsDescr {
  display: flex;
}
.tab {
  flex: 0 0 33%;
  cursor: pointer;
}
.tab.active {
  color: white; /*#353535;*/
  background: #d35316;
}
.no-right-padding {
  padding-top: 1rem;
  padding-left: 0px;
  margin-bottom: 0px;
}
hr {
  margin-top: 1em;
}

.infoTooltip {
  position: absolute;
  display: inline-block;
  /*border-bottom: 1px dotted black; /* If you want dots under the hoverable text */

  /* border: 1px solid;
  border-radius: 32px;
  width: 50px;
  height: 50px; */
}
.infoTooltip img {
  width: 20px;
  height: 20px;
  margin-left: 3px;
}

/* Tooltip text */
.infoTooltip .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: #d35316;
  color: #fff;
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;

  /* Position the tooltip text - see examples below! */
  position: absolute;
  z-index: 1;
}

/* Show the tooltip text when you mouse over the tooltip container */
.infoTooltip:hover .tooltiptext {
  visibility: visible;
}

.infoTooltip .tooltiptext.active {
  visibility: visible;
}
</style>

<template>
  <!-- <div class="faq padding-box">
    <h3 class="warranty-faq-title">
      Fragen und Antworten zur Garantieverlängerung
    </h3>
    <div class="question-wrapper">
      <div class="question _toggler-header">
        Was heisst plus 2 Jahre Garantie?
      </div>
      <div class="answer _toggler-target">
        Als Teilnehmer im Delizio Bonusprogramm erhalten Sie ab sofort zur
        gesetzlichen Garantie von zwei Jahren weitere zwei Jahre Garantie von
        Delizio geschenkt. Somit profitieren Sie insgesamt von vier Jahren
        Garantie. Dies gilt für alle neu gekauften Maschinen sowie Maschinen,
        die sich aktuell noch im Garantiezeitraum befinden.
      </div>
    </div>
    <div class="question-wrapper">
      <div class="question _toggler-header">
        Was heisst plus 2 Jahre Garantie?
      </div>
      <div class="answer _toggler-target">
        Als Teilnehmer im Delizio Bonusprogramm erhalten Sie ab sofort zur
        gesetzlichen Garantie von zwei Jahren weitere zwei Jahre Garantie von
        Delizio geschenkt. Somit profitieren Sie insgesamt von vier Jahren
        Garantie. Dies gilt für alle neu gekauften Maschinen sowie Maschinen,
        die sich aktuell noch im Garantiezeitraum befinden.
      </div>
    </div>
  </div> -->
  <aside id="faq">
    <div class="row">
      <div class="columns c-12">
        <h2 class="" v-html="$t('faq.headline')"></h2>
        <div
          class="ui-accordion"
          v-for="(faq, index) in Object.keys(
            $i18n.messages[$i18n.locale].faq.questions
          )"
          :key="index"
        >
          <div
            class="ui-accordion-header"
            :class="{ open: accordionActive[index] }"
            @click="accordionFunction(index)"
          >
            <p>{{ $t(`faq.questions[${faq}].question`) }}</p>
          </div>
          <Transition name="fade">
            <div
              class="ui-accordion-body open"
              v-if="accordionActive[index]"
              v-html="$t(`faq.questions[${faq}].answer`)"
            ></div>
          </Transition>
        </div>
      </div>
    </div>
  </aside>
  <hr />
</template>

<script>
export default {
  name: "Faq",
  data: function() {
    return {
      accordionActive: [],
    };
  },
  methods: {
    accordionFunction(index) {
      this.accordionActive[index] = !this.accordionActive[index];
    },
  },
};
</script>
<style></style>

<template>
  <section id="customerDataForm" v-bind:class="tabActive ? 'active' : ''">
    <div id="form-section" class="form-section">
      <!-- <p v-html="$t('machineInformation.contactHeadline')"></p> -->
      <form id="contactData">
        <div class="columns c-12 m-12 no-padding">
          <div class="row">
            <div
              class="ui-form-item columns c-12"
              :class="{
                hasError: v$.form.gender.$errors.length > 0,
                hasSuccess: !v$.form.gender.$invalid,
              }"
            >
              <label
                for="gender"
                v-html="$t('machineInformation.gender.label')"
              ></label>
              <div class="select-box">
                <select
                  name="gender"
                  id="gender"
                  v-model="v$.form.gender.$model"
                >
                  <option
                    disabled
                    value=""
                    v-html="$t('machineInformation.gender.labeloption')"
                  ></option>
                  <option
                    :value="$t('machineInformation.gender.option1')"
                    v-html="$t('machineInformation.gender.option1')"
                  ></option>
                  <option
                    :value="$t('machineInformation.gender.option2')"
                    v-html="$t('machineInformation.gender.option2')"
                  ></option>
                </select>
                <div class="select-box-trigger">
                  <i class="icon-ui-icon-down-dir"></i>
                </div>
              </div>
              <div
                class="input-errors"
                v-for="(error, index) of v$.form.gender.$errors"
                :key="index"
              >
                <div class="error-msg">{{ error.$message }}</div>
              </div>
            </div>
          </div>
        </div>

        <div class="columns c-12 m-12 no-padding">
          <div class="row">
            <div
              class="ui-form-item columns c-6"
              :class="{
                hasError: v$.form.name.$errors.length > 0,
                hasSuccess: !v$.form.name.$invalid,
              }"
            >
              <label for="name" v-html="$t('machineInformation.name')"></label>
              <input
                type="text"
                name="name"
                id="name"
                :placeholder="$t('machineInformation.name')"
                v-model="v$.form.name.$model"
              />
              <div
                class="input-errors"
                v-for="(error, index) of v$.form.name.$errors"
                :key="index"
              >
                <div class="error-msg">{{ error.$message }}</div>
              </div>
            </div>

            <div
              class="ui-form-item columns c-6"
              :class="{
                hasError: v$.form.firstname.$errors.length > 0,
                hasSuccess: !v$.form.firstname.$invalid,
              }"
            >
              <label
                for="street"
                v-html="$t('machineInformation.firstname')"
              ></label>
              <input
                type="text"
                name="firstname"
                id="firstname"
                :placeholder="$t('machineInformation.firstname')"
                v-model="v$.form.firstname.$model"
              />
              <div
                class="input-errors"
                v-for="(error, index) of v$.form.firstname.$errors"
                :key="index"
              >
                <div class="error-msg">{{ error.$message }}</div>
              </div>
            </div>
          </div>
        </div>
        <div class="columns c-12 m-12 no-padding">
          <div class="row">
            <div
              class="ui-form-item columns c-9"
              :class="{
                hasError: v$.form.street.$errors.length > 0,
                hasSuccess: !v$.form.street.$invalid,
              }"
            >
              <label
                for="street"
                v-html="$t('machineInformation.street')"
              ></label>
              <input
                type="text"
                name="street"
                id="street"
                :placeholder="$t('machineInformation.street')"
                v-model="v$.form.street.$model"
              />
              <div
                class="input-errors"
                v-for="(error, index) of v$.form.street.$errors"
                :key="index"
              >
                <div class="error-msg">{{ error.$message }}</div>
              </div>
            </div>
            <div
              class="ui-form-item columns c-3"
              :class="{
                hasError: v$.form.streetNumber.$errors.length > 0,
                hasSuccess: !v$.form.streetNumber.$invalid,
              }"
            >
              <label
                for="streetnumber"
                v-html="$t('machineInformation.streetNumber')"
              ></label>
              <input
                type="text"
                name="streetNumber"
                id="streetNumber"
                :placeholder="$t('machineInformation.streetNumber')"
                v-model="v$.form.streetNumber.$model"
              />
              <div
                class="input-errors"
                v-for="(error, index) of v$.form.streetNumber.$errors"
                :key="index"
              >
                <div class="error-msg">{{ error.$message }}</div>
              </div>
            </div>
          </div>
        </div>
        <div class="columns c-12 m-12 no-padding">
          <div class="row">
            <div
              class="ui-form-item columns c-4"
              :class="{
                hasError: v$.form.postcode.$errors.length > 0,
                hasSuccess: !v$.form.postcode.$invalid,
              }"
            >
              <label
                for="postcode"
                v-html="$t('machineInformation.postcode')"
              ></label>
              <input
                type="text"
                name="postcode"
                id="postcode"
                :placeholder="$t('machineInformation.postcode')"
                v-model="v$.form.postcode.$model"
              />
              <div
                class="input-errors"
                v-for="(error, index) of v$.form.postcode.$errors"
                :key="index"
              >
                <div class="error-msg">{{ error.$message }}</div>
              </div>
            </div>
            <div
              class="ui-form-item columns c-8"
              :class="{
                hasError: v$.form.city.$errors.length > 0,
                hasSuccess: !v$.form.city.$invalid,
              }"
            >
              <label for="city" v-html="$t('machineInformation.city')"></label>
              <input
                type="text"
                name="city"
                id="city"
                :placeholder="$t('machineInformation.city')"
                v-model="v$.form.city.$model"
              />
              <div
                class="input-errors"
                v-for="(error, index) of v$.form.city.$errors"
                :key="index"
              >
                <div class="error-msg">{{ error.$message }}</div>
              </div>
            </div>
          </div>
        </div>
        <div class="columns c-12 m-12 no-padding">
          <div class="row">
            <div
              class="ui-form-item columns c-12"
              :class="{
                hasError: v$.form.country.$errors.length > 0,
                hasSuccess: !v$.form.country.$invalid,
              }"
            >
              <label
                for="country"
                v-html="$t('machineInformation.country.label')"
              ></label>
              <div class="select-box">
                <select
                  name="country"
                  id="country"
                  v-model="v$.form.country.$model"
                >
                  <option
                    disabled
                    value=""
                    v-html="$t('machineInformation.country.labeloption')"
                  ></option>
                  <option
                    :value="$t('machineInformation.country.option1')"
                    v-html="$t('machineInformation.country.option1')"
                  ></option>
                  <option
                    :value="$t('machineInformation.country.option2')"
                    v-html="$t('machineInformation.country.option2')"
                  ></option>
                </select>
                <div class="select-box-trigger">
                  <i class="icon-ui-icon-down-dir"></i>
                </div>
              </div>
              <div
                class="input-errors"
                v-for="(error, index) of v$.form.country.$errors"
                :key="index"
              >
                <div class="error-msg">{{ error.$message }}</div>
              </div>
            </div>
          </div>
        </div>

        <div class="columns c-12 m-12 no-padding">
          <div class="row">
            <div
              class="ui-form-item columns c-12"
              :class="{
                hasError: v$.form.birthday.$errors.length > 0,
                hasSuccess: !v$.form.birthday.$invalid,
              }"
            >
              <label
                for="birthday"
                v-html="$t('machineInformation.birthday')"
              ></label>
              <input
                type="date"
                name="birthday"
                id="birthday"
                :placeholder="$t('machineInformation.birthday')"
                v-model="v$.form.birthday.$model"
                :max="birthCheckDate"
                :min="birthMinDate"
              />
              <div
                class="input-errors"
                v-for="(error, index) of v$.form.birthday.$errors"
                :key="index"
              >
                <div class="error-msg">{{ error.$message }}</div>
              </div>
            </div>
          </div>
        </div>

        <div class="columns c-12 m-12 no-padding">
          <div class="row">
            <div
              class="ui-form-item columns c-12"
              :class="{
                hasError: v$.form.email.$errors.length > 0 || emailExists,
                hasSuccess: !v$.form.email.$invalid,
              }"
            >
              <label
                for="email"
                v-html="$t('machineInformation.email')"
              ></label>
              <input
                type="email"
                name="email"
                id="email"
                :placeholder="$t('machineInformation.email')"
                v-model="v$.form.email.$model"
              />

              <div
                class="input-errors"
                v-for="(error, index) of v$.form.email.$errors"
                :key="index"
              >
                <div class="error-msg">{{ error.$message }}</div>
              </div>
              <div class="input-errors" v-if="emailExists">
                <div
                  class="error-msg"
                  v-html="$t('validations.emailAlreadyExists')"
                ></div>
              </div>
            </div>
          </div>
          <div class="row">
            <div
              class="ui-form-item columns c-12"
              :class="{
                hasError: v$.form.checkTerms.$errors.length > 0,
                hasSuccess: !v$.form.checkTerms.$invalid,
              }"
            >
              <div class="check-item">
                <input
                  type="checkbox"
                  name="checkTerms"
                  id="checkTerms"
                  v-model="v$.form.checkTerms.$model"
                />
                <label
                  for="checkTerms"
                  v-html="$t('competitionFormular.checkTerms.label')"
                ></label>
              </div>
              <div
                class="input-errors"
                v-for="(error, index) of v$.form.checkTerms.$errors"
                :key="index"
              >
                <div class="error-msg">
                  {{ $t("validations.acceptTearms") }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  </section>
</template>

<script>
import useVuelidate from "@vuelidate/core";
import { required, email } from "../utils/i18n-validators";

export default {
  name: "AddressForm",
  setup() {
    return { v$: useVuelidate() };
  },
  props: {
    display: Boolean,
  },
  watch: {
    display(val) {
      console.log(val);
      this.tabActive = val;
    },
  },
  data() {
    return {
      tabActive: this.display || false,
      buttonLoader: false,
      submitted: false,
      alreadyRegistered: false,
      contactForm: {
        display: true,
      },
      date: "",
      color: "",
      form: {
        gender: "",
        firstname: "",
        name: "",
        street: "",
        streetNumber: "",
        city: "",
        postcode: "",
        country: "",
        birthday: "",
        email: "",
        checkTerms: "",
      },
      birthCheckDate: new Date(
        new Date().setFullYear(new Date().getFullYear() - 18)
        // .toLocaleDateString("de")
      )
        .toISOString()
        .split("T")[0],
      birthMinDate: new Date("1915-01-01").toISOString().split("T")[0],
    };
  },
  validations() {
    return {
      form: {
        gender: {
          required,
        },
        firstname: {
          required,
        },
        name: {
          required,
        },
        street: {
          required,
        },
        streetNumber: {
          required,
        },
        city: {
          required,
        },
        postcode: {
          required,
        },
        country: {
          required,
        },
        email: {
          required,
          email,
        },
        birthday: {
          required,
          maxValue(val) {
            console.log(new Date(val));
            console.log(
              new Date(new Date().setFullYear(new Date().getFullYear() - 18))
            );
            console.log(
              new Date(val) <
                new Date(new Date().setFullYear(new Date().getFullYear() - 18))
            );
            return (
              new Date(val) <
              new Date(new Date().setFullYear(new Date().getFullYear() - 18))
            );
          },
          minValue(val) {
            return new Date(val) > new Date("1915-01-01");
          },
        },
        checkTerms: {
          required,
        },
      },
    };
  },
  methods: {
    validateForm() {
      this.v$.form.$touch();
      if (this.v$.form.$pending || this.v$.form.$error) {
        console.log("error");
        return false;
      }
      return true;
    },
    submitForm() {
      this.buttonLoader = true;
      setTimeout(() => {
        this.v$.form.$touch();
        if (this.v$.form.$pending || this.v$.form.$error) {
          this.buttonLoader = false;
          return;
        }

        let registerForm = document.getElementById("contactData");
        let formData = new FormData(registerForm);
        formData.append("language", this.$i18n.locale);
        formData.append("date", this.date);
        // console.log(this.color);
        formData.append("color", this.color);
        formData.append("market", this.stores.value);
        formData.append("serial", this.usedSerial);

        fetch(this.apiUrl + "api/formcommit", {
          method: "POST",
          mode: "cors",
          body: formData,
        })
          .then((response) => {
            if (response.status == 400) {
              console.log("validation error");
              this.buttonLoader = false;
              return;
            }
            if (response.status == 480) {
              // console.log("serial already exists");
              this.alreadyRegistered = true;
              this.emailExists = false;
              this.buttonLoader = false;
              return;
            }
            if (response.status == 409) {
              // console.log("E-Mail already exists");
              this.emailExists = true;
              this.alreadyRegistered = false;
              this.buttonLoader = false;
              return;
            }
            if (response.status == 201) {
              // console.log("User created");
              this.submitted = true;
              this.alreadyRegistered = false;
              this.emailExists = false;
              this.buttonLoader = false;
              return;
            }
          })
          .catch((e) => console.log(e));

        //DUMMY
        // this.buttonLoader = false;
        // this.submitted = true;
        // console.log(
        //   `Current Serial = ${this.usedSerial} \nColor = ${this.color} \nVorname = ${this.form.firstname} \nNachname = ${this.form.name} \nE-Mail = ${this.form.email} \n`
        // );
      }, 1000);
    },
  },
};
</script>

<template>
  <div class="header-title-wrapper">
    <h3 v-html="$t('textBlock.headline')"></h3>
    <p v-html="$t('textBlock.paragraph1')"></p>
    <p v-html="$t('textBlock.paragraph2')"></p>
    <p v-html="$t('textBlock.paragraph3')"></p>
  </div>
  <!-- <section class="ui-step-by-step-box has-text-align-center padding-box">
    <div class="row">
      <div class="columns c-12">
        <h2 class="h3 ui-section-headline" v-html="$t('stepBox.headline')"></h2>
        <p
          class="ui-section-subheadline"
          v-html="$t('stepBox.subheadline')"
        ></p>
      </div>
    </div> 
    <div class="row stepItem">
     <StepItem
        :img="$t('stepBox.item.1.img')"
        :headline="$t('stepBox.item.1.headline')"
      ></StepItem> 
      <StepItem
        :img="$t('stepBox.item.2.img')"
        :headline="$t('stepBox.item.2.headline')"
      ></StepItem>
      <StepItem
        :img="$t('stepBox.item.3.img')"
        :headline="$t('stepBox.item.3.headline')"
      ></StepItem>
      <StepItem
        :img="$t('stepBox.item.4.img')"
        :headline="$t('stepBox.item.4.headline')"
      ></StepItem>
    </div>
  </section> -->
</template>

<script>
// import StepItem from "./StepItem.vue";
export default {
  name: "TextBox",
  components: {
    // StepItem,
  },
};
</script>

<style>
.header-title-wrapper {
  padding: 30px 20px;
}
h3,
.h3 {
  text-transform: initial;
  margin-bottom: 0.5rem;
}
p {
  margin-top: 0;
  margin-bottom: 1rem;
}
.padding-box {
  padding: 0;
}
.stepItem {
  justify-content: center;
}
</style>

<template>
  <h2
    v-bind:style="tabActive ? 'display:block' : 'display:none'"
    v-html="$t('machineInformation.productHeadline')"
  ></h2>
  <section
    id="productSection"
    class="tiles-wrapper_ miniature-page-wrapper"
    v-bind:class="tabActive ? 'active' : ''"
  >
    <article
      class="product-miniature coffee-miniature _quantity-selector _size_selector"
      data-product-id="10173863"
    >
      <div class="image-wrapper">
        <a href="https://www.delizio.ch/de/kaffee-tee/kaffee/espresso-classico">
          <img
            class="img-responsive _product-main-image"
            src="/assets/image/products/Espresso-Classico_48-ProductImage_65renewable-raw-materialsTZQDkuJaFe8W1_200x200@2x.webp"
          />
        </a>
      </div>
      <div class="product-miniature-inner-wrapper">
        <div class="product-name-wrapper">
          <h5 class="product-name">
            <a
              href="https://www.delizio.ch/de/kaffee-tee/kaffee/espresso-classico"
              >Espresso Classico</a
            >
          </h5>
          <hr class="hr-cross-sell" />
        </div>
        <div class="partial-attribute-wrapper product-type-wrapper ">
          <span class="label"> Sorte </span>
          <span class="value"> Espresso </span>
        </div>
        <div class="partial-attribute-wrapper product-cup-size-wrapper">
          <span class="label"> Tassengrösse </span>
          <div class="value product-cup-sizes">
            <span class="item">
              <svg viewBox="0 0 38 50">
                <g>
                  <path
                    d="M14.48,41.46h7.67c1.78,0,2.6-2,3-3.94l0.27,0h0c2,0,4-2.09,4.46-4.66,0.46-2.87-.86-5.49-3-5.82a3.13,3.13,0,0,0-1.44.12V26H11.15v8.27S11.15,41.42,14.48,41.46Zm11.33-12a1.18,1.18,0,0,1,.64-0.25h0.12a1.08,1.08,0,0,1,.64.47A4.38,4.38,0,0,1,26.34,35a1.21,1.21,0,0,1-.66.25H25.61l-0.11,0a0.41,0.41,0,0,1-.18-0.05h0l0.13-5.34A2.09,2.09,0,0,1,25.81,29.49ZM13.38,28.24h9.89v6c0,0.16,0,.33,0,0.49H14.7v1.87A11.37,11.37,0,0,0,15,39.23H14.48c-0.45-.31-1.1-2.66-1.11-4.94v-6Z"
                  ></path>
                  <path
                    d="M4.44,44v1.33h0a1.9,1.9,0,0,0,1.76,2H30.28a1.9,1.9,0,0,0,1.76-2h0V44H4.44Z"
                  ></path>
                </g>
              </svg>
            </span>
            <span class="item">
              <svg viewBox="0 0 38 50" class="selected">
                <g>
                  <path
                    d="M14.56,41.46h7.67c1.78,0,2.6-2,3-3.94l0.27,0h0c2,0,4-2.09,4.46-4.66,0.46-2.87-.86-5.49-3-5.82a3.13,3.13,0,0,0-1.44.12V26H11.23v8.27S11.23,41.42,14.56,41.46Zm11.33-12a1.18,1.18,0,0,1,.64-0.25h0.12a1.08,1.08,0,0,1,.64.47A4.38,4.38,0,0,1,26.42,35a1.21,1.21,0,0,1-.66.25H25.68l-0.11,0a0.41,0.41,0,0,1-.18-0.05h0l0.13-5.34A2.09,2.09,0,0,1,25.89,29.49ZM13.45,28.24h9.89v2c0,0.16,0,.33,0,0.49H14.78v5.87a11.37,11.37,0,0,0,.28,2.57H14.56c-0.45-.31-1.1-2.66-1.11-4.94v-6Z"
                  ></path>
                  <path
                    d="M4.52,44v1.33h0a1.9,1.9,0,0,0,1.76,2H30.36a1.9,1.9,0,0,0,1.76-2h0V44H4.52Z"
                  ></path>
                </g>
              </svg>
            </span>
            <span class="item">
              <svg viewBox="0 0 38 50">
                <g>
                  <path
                    d="M34.31,23.53a4.4,4.4,0,0,0-3.19-2.61,4.08,4.08,0,0,0-2.43.39V19.45H8.29V31.23S8,41.47,13,41.46H24c3,0,4.1-3.63,4.51-6.57h0a3.74,3.74,0,0,0,.57,0c2.7,0,5.36-2.81,5.91-6.26A8.66,8.66,0,0,0,34.31,23.53Zm-23.8,7.76V21.68h16V25H12.07v9.37a13,13,0,0,0,.79,4.86,1,1,0,0,1-.63-0.34C11,37.56,10.46,33.65,10.51,31.29Zm22.23-3a6.06,6.06,0,0,1-2.1,3.87,2.1,2.1,0,0,1-1.2.45l-0.28,0a0.92,0.92,0,0,1-.47-0.15c0-.74,0-1.21,0-1.21V24.15a3.77,3.77,0,0,1,.65-0.62,2,2,0,0,1,1.42-.42A2,2,0,0,1,32,24,6,6,0,0,1,32.74,28.31Z"
                  ></path>
                  <path
                    d="M2.92,45.36h0a2,2,0,0,0,2,2H32.1a2,2,0,0,0,2-2h0V44H2.92v1.33Z"
                  ></path>
                </g>
              </svg>
            </span>
            <span class="item">
              <svg viewBox="0 0 38 50">
                <g>
                  <path
                    d="M9,34.6c0.2,2.6,1,6.8,4.4,6.8h10.1c3.4,0,4.3-4.3,4.5-6.8l1-19.4H8L9,34.6z M26.8,17.3l-0.2,4.6H11.9l0.4,8 h13.8L26,34.6c-0.1,1.3-0.5,5-2.4,5H13.4c-1.9,0-2.3-3.6-2.4-5l-0.2-4.7l-0.6-12.6H26.8z"
                  ></path>
                  <path
                    d="M3.7,44v1.3c-0.1,1,0.7,1.9,1.8,2h26.1c1-0.1,1.8-1,1.8-2V44H3.7z"
                  ></path>
                  <path
                    d="M10,13.6c0-0.1,0-0.4,0.1-0.8c0.1-0.4,0.2-0.8,0.6-1.1c0.5-0.4,1.3,0,1.7,0.2c0.5,0.3,0.8,0.2,1,0.1 c0.2-0.1,0.5-0.3,0.7-0.7c0.3-0.9,0.9-2,2.4-2.3c2-0.4,3.4,0.8,3.5,0.8c0.3,0.2,0.7,0.3,1,0.2c0.1,0,2.2-0.7,2.9,0.6 c0.2,0.4,0.6,0.6,1,0.5c0,0,0.8,0,1.4,0.5c0.4,0.4,0.6,1,0.6,1.9h2c0-1.5-0.4-2.6-1.3-3.4c-0.8-0.7-1.7-0.9-2.3-1 c-1.2-1.5-3.2-1.5-4.5-1.2C20,7.4,18.3,6.5,16,7c-1.5,0.3-2.8,1.3-3.5,2.7c-1.1-0.4-2.2-0.3-3,0.3c-0.7,0.5-1.2,1.3-1.4,2.4 C8,12.9,8,13.4,8,13.6H10z"
                  ></path>
                </g>
              </svg>
            </span>
          </div>
        </div>
        <hr class="hr-after-product-cup-size" />
        <div class="partial-attribute-wrapper product-intensity-wrapper">
          <span class="label"> Stärkegrad </span>
          <div class="value">
            <span>1</span> <span>2</span> <span class="selected">3</span>
            <span>4</span> <span>5</span>
          </div>
        </div>
        <hr class="hr-after-product-intensity" />
        <div class="partial-attribute-wrapper coffee-product-size-wrapper">
          <p
            class="label no-editable "
            v-html="$t('machineInformation.productPackage')"
          ></p>
          <div class="value">
            <button
              class="btn-package-size _size-selector-trigger selected"
              data-product-id="10176082"
              data-price="19.80"
              data-old-price="0"
              data-referenceprice="41.25"
              data-bonus-price="48"
              data-size-selector-index="1"
            >
              48
            </button>
          </div>
        </div>
        <hr />
        <div class="partial-attribute-wrapper product-price-wrapper">
          <div class="label">
            <p class="price">
              <span class="_product-price">19.80</span>
              <span class="_product-old-price-target old-price" hidden="true"
                >0</span
              >
            </p>
            <p class="price-tag _product-price-tag"></p>
            <p class="price base-price">
              <span class="product-base-price"
                >(CHF <span class="product-base-price-tag">41.25</span> /
              </span>
              <span class="product-base-unit">100Stck.)</span>
            </p>
          </div>
          <div class="value">
            <span class="disclaimer_"
              >* Inkl. MwSt, versandkostenfrei ab CHF 20.</span
            >
            <div class="quantity-wrapper">
              <div class="product-quantity-selector">
                <button
                  class="remove _remove "
                  @click="changeOrderValue('p10176082', '-')"
                >
                  -
                </button>
                <span class="quantity-label _quantity-label">{{
                  product.p10176082
                }}</span>
                <button
                  class="add _add "
                  @click="changeOrderValue('p10176082')"
                >
                  +
                </button>
              </div>
            </div>
            <div class="disclaimer_ bonus-string">
              <div
                class="mobile-tooltip _mobile-tooltip"
                style="display: none;"
              >
                Jeder Kauf einer Delizio Kapselpackung lohnt sich: Sammeln Sie
                On- oder Offline wertvolle Genusspunkte und lösen Sie diese bei
                Ihrer nächsten Online-Bestellung ein.
              </div>
              Für dieses Produkt erhalten Sie
              <span class="_bonus-price">48</span> Punkte
              <span
                class="info-link _reward-tooltip"
                data-container="section"
                title=""
                data-original-title="Jeder Kauf einer Delizio Kapselpackung lohnt sich: Sammeln Sie On- oder Offline wertvolle Genusspunkte und lösen Sie diese bei Ihrer nächsten Online-Bestellung ein."
                >i</span
              >
            </div>
          </div>
        </div>
        <hr />
      </div>
      <div class="miniature-fake-element"></div>
    </article>
    <article
      class="product-miniature coffee-miniature _quantity-selector _size_selector"
      data-product-id="10172904"
    >
      <div class="image-wrapper">
        <a href="https://www.delizio.ch/de/kaffee-tee/kaffee/lungo-crema">
          <img
            class="img-responsive _product-main-image"
            src="/assets/image/products/Lungo-Crema_48-ProductImage_65renewable-raw-materialsbGqQWeMwqXJ0h_200x200@2x.webp"
          />
        </a>
      </div>
      <div class="product-miniature-inner-wrapper">
        <div class="product-name-wrapper">
          <h5 class="product-name">
            <a href="https://www.delizio.ch/de/kaffee-tee/kaffee/lungo-crema"
              >Lungo Crema</a
            >
          </h5>
          <hr class="hr-cross-sell" />
        </div>
        <div class="partial-attribute-wrapper product-type-wrapper ">
          <span class="label"> Sorte </span> <span class="value"> Lungo </span>
        </div>
        <div class="partial-attribute-wrapper product-cup-size-wrapper">
          <span class="label"> Tassengrösse </span>
          <div class="value product-cup-sizes">
            <span class="item">
              <svg viewBox="0 0 38 50">
                <g>
                  <path
                    d="M14.48,41.46h7.67c1.78,0,2.6-2,3-3.94l0.27,0h0c2,0,4-2.09,4.46-4.66,0.46-2.87-.86-5.49-3-5.82a3.13,3.13,0,0,0-1.44.12V26H11.15v8.27S11.15,41.42,14.48,41.46Zm11.33-12a1.18,1.18,0,0,1,.64-0.25h0.12a1.08,1.08,0,0,1,.64.47A4.38,4.38,0,0,1,26.34,35a1.21,1.21,0,0,1-.66.25H25.61l-0.11,0a0.41,0.41,0,0,1-.18-0.05h0l0.13-5.34A2.09,2.09,0,0,1,25.81,29.49ZM13.38,28.24h9.89v6c0,0.16,0,.33,0,0.49H14.7v1.87A11.37,11.37,0,0,0,15,39.23H14.48c-0.45-.31-1.1-2.66-1.11-4.94v-6Z"
                  ></path>
                  <path
                    d="M4.44,44v1.33h0a1.9,1.9,0,0,0,1.76,2H30.28a1.9,1.9,0,0,0,1.76-2h0V44H4.44Z"
                  ></path>
                </g>
              </svg>
            </span>
            <span class="item">
              <svg viewBox="0 0 38 50">
                <g>
                  <path
                    d="M14.56,41.46h7.67c1.78,0,2.6-2,3-3.94l0.27,0h0c2,0,4-2.09,4.46-4.66,0.46-2.87-.86-5.49-3-5.82a3.13,3.13,0,0,0-1.44.12V26H11.23v8.27S11.23,41.42,14.56,41.46Zm11.33-12a1.18,1.18,0,0,1,.64-0.25h0.12a1.08,1.08,0,0,1,.64.47A4.38,4.38,0,0,1,26.42,35a1.21,1.21,0,0,1-.66.25H25.68l-0.11,0a0.41,0.41,0,0,1-.18-0.05h0l0.13-5.34A2.09,2.09,0,0,1,25.89,29.49ZM13.45,28.24h9.89v2c0,0.16,0,.33,0,0.49H14.78v5.87a11.37,11.37,0,0,0,.28,2.57H14.56c-0.45-.31-1.1-2.66-1.11-4.94v-6Z"
                  ></path>
                  <path
                    d="M4.52,44v1.33h0a1.9,1.9,0,0,0,1.76,2H30.36a1.9,1.9,0,0,0,1.76-2h0V44H4.52Z"
                  ></path>
                </g>
              </svg>
            </span>
            <span class="item">
              <svg viewBox="0 0 38 50" class="selected">
                <g>
                  <path
                    d="M34.31,23.53a4.4,4.4,0,0,0-3.19-2.61,4.08,4.08,0,0,0-2.43.39V19.45H8.29V31.23S8,41.47,13,41.46H24c3,0,4.1-3.63,4.51-6.57h0a3.74,3.74,0,0,0,.57,0c2.7,0,5.36-2.81,5.91-6.26A8.66,8.66,0,0,0,34.31,23.53Zm-23.8,7.76V21.68h16V25H12.07v9.37a13,13,0,0,0,.79,4.86,1,1,0,0,1-.63-0.34C11,37.56,10.46,33.65,10.51,31.29Zm22.23-3a6.06,6.06,0,0,1-2.1,3.87,2.1,2.1,0,0,1-1.2.45l-0.28,0a0.92,0.92,0,0,1-.47-0.15c0-.74,0-1.21,0-1.21V24.15a3.77,3.77,0,0,1,.65-0.62,2,2,0,0,1,1.42-.42A2,2,0,0,1,32,24,6,6,0,0,1,32.74,28.31Z"
                  ></path>
                  <path
                    d="M2.92,45.36h0a2,2,0,0,0,2,2H32.1a2,2,0,0,0,2-2h0V44H2.92v1.33Z"
                  ></path>
                </g>
              </svg>
            </span>
            <span class="item">
              <svg viewBox="0 0 38 50">
                <g>
                  <path
                    d="M9,34.6c0.2,2.6,1,6.8,4.4,6.8h10.1c3.4,0,4.3-4.3,4.5-6.8l1-19.4H8L9,34.6z M26.8,17.3l-0.2,4.6H11.9l0.4,8 h13.8L26,34.6c-0.1,1.3-0.5,5-2.4,5H13.4c-1.9,0-2.3-3.6-2.4-5l-0.2-4.7l-0.6-12.6H26.8z"
                  ></path>
                  <path
                    d="M3.7,44v1.3c-0.1,1,0.7,1.9,1.8,2h26.1c1-0.1,1.8-1,1.8-2V44H3.7z"
                  ></path>
                  <path
                    d="M10,13.6c0-0.1,0-0.4,0.1-0.8c0.1-0.4,0.2-0.8,0.6-1.1c0.5-0.4,1.3,0,1.7,0.2c0.5,0.3,0.8,0.2,1,0.1 c0.2-0.1,0.5-0.3,0.7-0.7c0.3-0.9,0.9-2,2.4-2.3c2-0.4,3.4,0.8,3.5,0.8c0.3,0.2,0.7,0.3,1,0.2c0.1,0,2.2-0.7,2.9,0.6 c0.2,0.4,0.6,0.6,1,0.5c0,0,0.8,0,1.4,0.5c0.4,0.4,0.6,1,0.6,1.9h2c0-1.5-0.4-2.6-1.3-3.4c-0.8-0.7-1.7-0.9-2.3-1 c-1.2-1.5-3.2-1.5-4.5-1.2C20,7.4,18.3,6.5,16,7c-1.5,0.3-2.8,1.3-3.5,2.7c-1.1-0.4-2.2-0.3-3,0.3c-0.7,0.5-1.2,1.3-1.4,2.4 C8,12.9,8,13.4,8,13.6H10z"
                  ></path>
                </g>
              </svg>
            </span>
          </div>
        </div>
        <hr class="hr-after-product-cup-size" />
        <div class="partial-attribute-wrapper product-intensity-wrapper">
          <span class="label"> Stärkegrad </span>
          <div class="value">
            <span>1</span> <span>2</span> <span class="selected">3</span>
            <span>4</span> <span>5</span>
          </div>
        </div>
        <hr class="hr-after-product-intensity" />
        <div class="partial-attribute-wrapper coffee-product-size-wrapper">
          <p
            class="label no-editable "
            v-html="$t('machineInformation.productPackage')"
          ></p>
          <div class="value">
            <button
              class="btn-package-size _size-selector-trigger selected"
              data-product-id="10168322"
              data-price="19.80"
              data-old-price="0"
              data-referenceprice="41.25"
              data-bonus-price="48"
              data-size-selector-index="1"
            >
              48
            </button>
          </div>
        </div>
        <hr />
        <div class="partial-attribute-wrapper product-price-wrapper">
          <div class="label">
            <p class="price">
              <span class="_product-price">19.80</span>
              <span class="_product-old-price-target old-price" hidden="true"
                >0</span
              >
            </p>
            <p class="price-tag _product-price-tag"></p>
            <p class="price base-price">
              <span class="product-base-price"
                >(CHF <span class="product-base-price-tag">41.25</span> /
              </span>
              <span class="product-base-unit">100Stck.)</span>
            </p>
          </div>
          <div class="value">
            <span class="disclaimer_"
              >* Inkl. MwSt, versandkostenfrei ab CHF 20.</span
            >
            <div class="quantity-wrapper">
              <div class="product-quantity-selector">
                <button
                  class="remove _remove "
                  @click="changeOrderValue('p10168322', '-')"
                >
                  -
                </button>
                <span class="quantity-label _quantity-label">{{
                  product.p10168322
                }}</span>
                <button
                  class="add _add "
                  @click="changeOrderValue('p10168322')"
                >
                  +
                </button>
              </div>
            </div>
            <div class="disclaimer_ bonus-string">
              <div
                class="mobile-tooltip _mobile-tooltip"
                style="display: none;"
              >
                Jeder Kauf einer Delizio Kapselpackung lohnt sich: Sammeln Sie
                On- oder Offline wertvolle Genusspunkte und lösen Sie diese bei
                Ihrer nächsten Online-Bestellung ein.
              </div>
              Für dieses Produkt erhalten Sie
              <span class="_bonus-price">48</span> Punkte
              <span
                class="info-link _reward-tooltip"
                data-container="section"
                title=""
                data-original-title="Jeder Kauf einer Delizio Kapselpackung lohnt sich: Sammeln Sie On- oder Offline wertvolle Genusspunkte und lösen Sie diese bei Ihrer nächsten Online-Bestellung ein."
                >i</span
              >
            </div>
          </div>
        </div>
        <hr />
      </div>
      <div class="miniature-fake-element"></div>
    </article>
    <article
      class="product-miniature coffee-miniature _quantity-selector _size_selector"
      data-product-id="10170588"
    >
      <div class="image-wrapper">
        <a href="https://www.delizio.ch/de/kaffee-tee/kaffee/lungo-leggero">
          <img
            class="img-responsive _product-main-image"
            src="/assets/image/products/Lungo-Leggero_48-ProductImage_65renewable-raw-materialsQCHNubSPpP1WM_200x200@2x.webp"
          />
        </a>
      </div>
      <div class="product-miniature-inner-wrapper">
        <div class="product-name-wrapper">
          <h5 class="product-name">
            <a href="https://www.delizio.ch/de/kaffee-tee/kaffee/lungo-leggero"
              >Lungo Leggero</a
            >
          </h5>
          <hr class="hr-cross-sell" />
        </div>
        <div class="partial-attribute-wrapper product-type-wrapper ">
          <span class="label"> Sorte </span> <span class="value"> Lungo </span>
        </div>
        <div class="partial-attribute-wrapper product-cup-size-wrapper">
          <span class="label"> Tassengrösse </span>
          <div class="value product-cup-sizes">
            <span class="item">
              <svg viewBox="0 0 38 50">
                <g>
                  <path
                    d="M14.48,41.46h7.67c1.78,0,2.6-2,3-3.94l0.27,0h0c2,0,4-2.09,4.46-4.66,0.46-2.87-.86-5.49-3-5.82a3.13,3.13,0,0,0-1.44.12V26H11.15v8.27S11.15,41.42,14.48,41.46Zm11.33-12a1.18,1.18,0,0,1,.64-0.25h0.12a1.08,1.08,0,0,1,.64.47A4.38,4.38,0,0,1,26.34,35a1.21,1.21,0,0,1-.66.25H25.61l-0.11,0a0.41,0.41,0,0,1-.18-0.05h0l0.13-5.34A2.09,2.09,0,0,1,25.81,29.49ZM13.38,28.24h9.89v6c0,0.16,0,.33,0,0.49H14.7v1.87A11.37,11.37,0,0,0,15,39.23H14.48c-0.45-.31-1.1-2.66-1.11-4.94v-6Z"
                  ></path>
                  <path
                    d="M4.44,44v1.33h0a1.9,1.9,0,0,0,1.76,2H30.28a1.9,1.9,0,0,0,1.76-2h0V44H4.44Z"
                  ></path>
                </g>
              </svg>
            </span>
            <span class="item">
              <svg viewBox="0 0 38 50">
                <g>
                  <path
                    d="M14.56,41.46h7.67c1.78,0,2.6-2,3-3.94l0.27,0h0c2,0,4-2.09,4.46-4.66,0.46-2.87-.86-5.49-3-5.82a3.13,3.13,0,0,0-1.44.12V26H11.23v8.27S11.23,41.42,14.56,41.46Zm11.33-12a1.18,1.18,0,0,1,.64-0.25h0.12a1.08,1.08,0,0,1,.64.47A4.38,4.38,0,0,1,26.42,35a1.21,1.21,0,0,1-.66.25H25.68l-0.11,0a0.41,0.41,0,0,1-.18-0.05h0l0.13-5.34A2.09,2.09,0,0,1,25.89,29.49ZM13.45,28.24h9.89v2c0,0.16,0,.33,0,0.49H14.78v5.87a11.37,11.37,0,0,0,.28,2.57H14.56c-0.45-.31-1.1-2.66-1.11-4.94v-6Z"
                  ></path>
                  <path
                    d="M4.52,44v1.33h0a1.9,1.9,0,0,0,1.76,2H30.36a1.9,1.9,0,0,0,1.76-2h0V44H4.52Z"
                  ></path>
                </g>
              </svg>
            </span>
            <span class="item">
              <svg viewBox="0 0 38 50" class="selected">
                <g>
                  <path
                    d="M34.31,23.53a4.4,4.4,0,0,0-3.19-2.61,4.08,4.08,0,0,0-2.43.39V19.45H8.29V31.23S8,41.47,13,41.46H24c3,0,4.1-3.63,4.51-6.57h0a3.74,3.74,0,0,0,.57,0c2.7,0,5.36-2.81,5.91-6.26A8.66,8.66,0,0,0,34.31,23.53Zm-23.8,7.76V21.68h16V25H12.07v9.37a13,13,0,0,0,.79,4.86,1,1,0,0,1-.63-0.34C11,37.56,10.46,33.65,10.51,31.29Zm22.23-3a6.06,6.06,0,0,1-2.1,3.87,2.1,2.1,0,0,1-1.2.45l-0.28,0a0.92,0.92,0,0,1-.47-0.15c0-.74,0-1.21,0-1.21V24.15a3.77,3.77,0,0,1,.65-0.62,2,2,0,0,1,1.42-.42A2,2,0,0,1,32,24,6,6,0,0,1,32.74,28.31Z"
                  ></path>
                  <path
                    d="M2.92,45.36h0a2,2,0,0,0,2,2H32.1a2,2,0,0,0,2-2h0V44H2.92v1.33Z"
                  ></path>
                </g>
              </svg>
            </span>
            <span class="item">
              <svg viewBox="0 0 38 50">
                <g>
                  <path
                    d="M9,34.6c0.2,2.6,1,6.8,4.4,6.8h10.1c3.4,0,4.3-4.3,4.5-6.8l1-19.4H8L9,34.6z M26.8,17.3l-0.2,4.6H11.9l0.4,8 h13.8L26,34.6c-0.1,1.3-0.5,5-2.4,5H13.4c-1.9,0-2.3-3.6-2.4-5l-0.2-4.7l-0.6-12.6H26.8z"
                  ></path>
                  <path
                    d="M3.7,44v1.3c-0.1,1,0.7,1.9,1.8,2h26.1c1-0.1,1.8-1,1.8-2V44H3.7z"
                  ></path>
                  <path
                    d="M10,13.6c0-0.1,0-0.4,0.1-0.8c0.1-0.4,0.2-0.8,0.6-1.1c0.5-0.4,1.3,0,1.7,0.2c0.5,0.3,0.8,0.2,1,0.1 c0.2-0.1,0.5-0.3,0.7-0.7c0.3-0.9,0.9-2,2.4-2.3c2-0.4,3.4,0.8,3.5,0.8c0.3,0.2,0.7,0.3,1,0.2c0.1,0,2.2-0.7,2.9,0.6 c0.2,0.4,0.6,0.6,1,0.5c0,0,0.8,0,1.4,0.5c0.4,0.4,0.6,1,0.6,1.9h2c0-1.5-0.4-2.6-1.3-3.4c-0.8-0.7-1.7-0.9-2.3-1 c-1.2-1.5-3.2-1.5-4.5-1.2C20,7.4,18.3,6.5,16,7c-1.5,0.3-2.8,1.3-3.5,2.7c-1.1-0.4-2.2-0.3-3,0.3c-0.7,0.5-1.2,1.3-1.4,2.4 C8,12.9,8,13.4,8,13.6H10z"
                  ></path>
                </g>
              </svg>
            </span>
          </div>
        </div>
        <hr class="hr-after-product-cup-size" />
        <div class="partial-attribute-wrapper product-intensity-wrapper">
          <span class="label"> Stärkegrad </span>
          <div class="value">
            <span class="selected">1</span> <span>2</span> <span>3</span>
            <span>4</span> <span>5</span>
          </div>
        </div>
        <hr class="hr-after-product-intensity" />
        <div class="partial-attribute-wrapper coffee-product-size-wrapper">
          <p
            class="label no-editable "
            v-html="$t('machineInformation.productPackage')"
          ></p>
          <div class="value">
            <button
              class="btn-package-size _size-selector-trigger selected"
              data-product-id="10170588"
              data-price="19.80"
              data-old-price="0"
              data-referenceprice="41.25"
              data-bonus-price="48"
              data-size-selector-index="0"
            >
              48
            </button>
          </div>
        </div>
        <hr />
        <div class="partial-attribute-wrapper product-price-wrapper">
          <div class="label">
            <p class="price">
              <span class="_product-price">19.80</span>
              <span class="_product-old-price-target old-price" hidden="true"
                >0</span
              >
            </p>
            <p class="price-tag _product-price-tag"></p>
            <p class="price base-price">
              <span class="product-base-price"
                >(CHF <span class="product-base-price-tag">41.25</span> /
              </span>
              <span class="product-base-unit">100Stck.)</span>
            </p>
          </div>
          <div class="value">
            <span class="disclaimer_"
              >* Inkl. MwSt, versandkostenfrei ab CHF 20.</span
            >
            <div class="quantity-wrapper">
              <div class="product-quantity-selector">
                <button
                  class="remove _remove "
                  @click="changeOrderValue('p10170588', '-')"
                >
                  -
                </button>
                <span class="quantity-label _quantity-label">{{
                  product.p10170588
                }}</span>
                <button
                  class="add _add "
                  @click="changeOrderValue('p10170588')"
                >
                  +
                </button>
              </div>
            </div>
            <div class="disclaimer_ bonus-string">
              <div
                class="mobile-tooltip _mobile-tooltip"
                style="display: none;"
              >
                Jeder Kauf einer Delizio Kapselpackung lohnt sich: Sammeln Sie
                On- oder Offline wertvolle Genusspunkte und lösen Sie diese bei
                Ihrer nächsten Online-Bestellung ein.
              </div>
              Für dieses Produkt erhalten Sie
              <span class="_bonus-price">48</span> Punkte
              <span
                class="info-link _reward-tooltip"
                data-container="section"
                title=""
                data-original-title="Jeder Kauf einer Delizio Kapselpackung lohnt sich: Sammeln Sie On- oder Offline wertvolle Genusspunkte und lösen Sie diese bei Ihrer nächsten Online-Bestellung ein."
                >i</span
              >
            </div>
          </div>
        </div>
        <hr />
      </div>
      <div class="miniature-fake-element"></div>
    </article>
    <article
      class="product-miniature coffee-miniature _quantity-selector _size_selector"
      data-product-id="10166928"
    >
      <div class="image-wrapper">
        <a href="https://www.delizio.ch/de/kaffee-tee/kaffee/lungo-fortissimo">
          <img
            class="img-responsive _product-main-image"
            src="/assets/image/products/Lungo-Fortissimo_48-ProductImage_65renewable-raw-materialsuK5XdY7z5qnGP_200x200@2x.webp"
          />
        </a>
      </div>
      <div class="product-miniature-inner-wrapper">
        <div class="product-name-wrapper">
          <h5 class="product-name">
            <a
              href="https://www.delizio.ch/de/kaffee-tee/kaffee/lungo-fortissimo"
              >Lungo Fortissimo</a
            >
          </h5>
          <hr class="hr-cross-sell" />
        </div>
        <div class="partial-attribute-wrapper product-type-wrapper ">
          <span class="label"> Sorte </span> <span class="value"> Lungo </span>
        </div>
        <div class="partial-attribute-wrapper product-cup-size-wrapper">
          <span class="label"> Tassengrösse </span>
          <div class="value product-cup-sizes">
            <span class="item">
              <svg viewBox="0 0 38 50">
                <g>
                  <path
                    d="M14.48,41.46h7.67c1.78,0,2.6-2,3-3.94l0.27,0h0c2,0,4-2.09,4.46-4.66,0.46-2.87-.86-5.49-3-5.82a3.13,3.13,0,0,0-1.44.12V26H11.15v8.27S11.15,41.42,14.48,41.46Zm11.33-12a1.18,1.18,0,0,1,.64-0.25h0.12a1.08,1.08,0,0,1,.64.47A4.38,4.38,0,0,1,26.34,35a1.21,1.21,0,0,1-.66.25H25.61l-0.11,0a0.41,0.41,0,0,1-.18-0.05h0l0.13-5.34A2.09,2.09,0,0,1,25.81,29.49ZM13.38,28.24h9.89v6c0,0.16,0,.33,0,0.49H14.7v1.87A11.37,11.37,0,0,0,15,39.23H14.48c-0.45-.31-1.1-2.66-1.11-4.94v-6Z"
                  ></path>
                  <path
                    d="M4.44,44v1.33h0a1.9,1.9,0,0,0,1.76,2H30.28a1.9,1.9,0,0,0,1.76-2h0V44H4.44Z"
                  ></path>
                </g>
              </svg>
            </span>
            <span class="item">
              <svg viewBox="0 0 38 50">
                <g>
                  <path
                    d="M14.56,41.46h7.67c1.78,0,2.6-2,3-3.94l0.27,0h0c2,0,4-2.09,4.46-4.66,0.46-2.87-.86-5.49-3-5.82a3.13,3.13,0,0,0-1.44.12V26H11.23v8.27S11.23,41.42,14.56,41.46Zm11.33-12a1.18,1.18,0,0,1,.64-0.25h0.12a1.08,1.08,0,0,1,.64.47A4.38,4.38,0,0,1,26.42,35a1.21,1.21,0,0,1-.66.25H25.68l-0.11,0a0.41,0.41,0,0,1-.18-0.05h0l0.13-5.34A2.09,2.09,0,0,1,25.89,29.49ZM13.45,28.24h9.89v2c0,0.16,0,.33,0,0.49H14.78v5.87a11.37,11.37,0,0,0,.28,2.57H14.56c-0.45-.31-1.1-2.66-1.11-4.94v-6Z"
                  ></path>
                  <path
                    d="M4.52,44v1.33h0a1.9,1.9,0,0,0,1.76,2H30.36a1.9,1.9,0,0,0,1.76-2h0V44H4.52Z"
                  ></path>
                </g>
              </svg>
            </span>
            <span class="item">
              <svg viewBox="0 0 38 50" class="selected">
                <g>
                  <path
                    d="M34.31,23.53a4.4,4.4,0,0,0-3.19-2.61,4.08,4.08,0,0,0-2.43.39V19.45H8.29V31.23S8,41.47,13,41.46H24c3,0,4.1-3.63,4.51-6.57h0a3.74,3.74,0,0,0,.57,0c2.7,0,5.36-2.81,5.91-6.26A8.66,8.66,0,0,0,34.31,23.53Zm-23.8,7.76V21.68h16V25H12.07v9.37a13,13,0,0,0,.79,4.86,1,1,0,0,1-.63-0.34C11,37.56,10.46,33.65,10.51,31.29Zm22.23-3a6.06,6.06,0,0,1-2.1,3.87,2.1,2.1,0,0,1-1.2.45l-0.28,0a0.92,0.92,0,0,1-.47-0.15c0-.74,0-1.21,0-1.21V24.15a3.77,3.77,0,0,1,.65-0.62,2,2,0,0,1,1.42-.42A2,2,0,0,1,32,24,6,6,0,0,1,32.74,28.31Z"
                  ></path>
                  <path
                    d="M2.92,45.36h0a2,2,0,0,0,2,2H32.1a2,2,0,0,0,2-2h0V44H2.92v1.33Z"
                  ></path>
                </g>
              </svg>
            </span>
            <span class="item">
              <svg viewBox="0 0 38 50">
                <g>
                  <path
                    d="M9,34.6c0.2,2.6,1,6.8,4.4,6.8h10.1c3.4,0,4.3-4.3,4.5-6.8l1-19.4H8L9,34.6z M26.8,17.3l-0.2,4.6H11.9l0.4,8 h13.8L26,34.6c-0.1,1.3-0.5,5-2.4,5H13.4c-1.9,0-2.3-3.6-2.4-5l-0.2-4.7l-0.6-12.6H26.8z"
                  ></path>
                  <path
                    d="M3.7,44v1.3c-0.1,1,0.7,1.9,1.8,2h26.1c1-0.1,1.8-1,1.8-2V44H3.7z"
                  ></path>
                  <path
                    d="M10,13.6c0-0.1,0-0.4,0.1-0.8c0.1-0.4,0.2-0.8,0.6-1.1c0.5-0.4,1.3,0,1.7,0.2c0.5,0.3,0.8,0.2,1,0.1 c0.2-0.1,0.5-0.3,0.7-0.7c0.3-0.9,0.9-2,2.4-2.3c2-0.4,3.4,0.8,3.5,0.8c0.3,0.2,0.7,0.3,1,0.2c0.1,0,2.2-0.7,2.9,0.6 c0.2,0.4,0.6,0.6,1,0.5c0,0,0.8,0,1.4,0.5c0.4,0.4,0.6,1,0.6,1.9h2c0-1.5-0.4-2.6-1.3-3.4c-0.8-0.7-1.7-0.9-2.3-1 c-1.2-1.5-3.2-1.5-4.5-1.2C20,7.4,18.3,6.5,16,7c-1.5,0.3-2.8,1.3-3.5,2.7c-1.1-0.4-2.2-0.3-3,0.3c-0.7,0.5-1.2,1.3-1.4,2.4 C8,12.9,8,13.4,8,13.6H10z"
                  ></path>
                </g>
              </svg>
            </span>
          </div>
        </div>
        <hr class="hr-after-product-cup-size" />
        <div class="partial-attribute-wrapper product-intensity-wrapper">
          <span class="label"> Stärkegrad </span>
          <div class="value">
            <span>1</span> <span>2</span> <span>3</span>
            <span class="selected">4</span> <span>5</span>
          </div>
        </div>
        <hr class="hr-after-product-intensity" />
        <div class="partial-attribute-wrapper coffee-product-size-wrapper">
          <p
            class="label no-editable "
            v-html="$t('machineInformation.productPackage')"
          ></p>
          <div class="value">
            <button
              class="btn-package-size _size-selector-trigger selected"
              data-product-id="10170342"
              data-price="19.80"
              data-old-price="0"
              data-referenceprice="41.25"
              data-bonus-price="48"
              data-size-selector-index="1"
            >
              48
            </button>
          </div>
        </div>
        <hr />
        <div class="partial-attribute-wrapper product-price-wrapper">
          <div class="label">
            <p class="price">
              <span class="_product-price">19.80</span>
              <span class="_product-old-price-target old-price" hidden="true"
                >0</span
              >
            </p>
            <p class="price-tag _product-price-tag"></p>
            <p class="price base-price">
              <span class="product-base-price"
                >(CHF <span class="product-base-price-tag">41.25</span> /
              </span>
              <span class="product-base-unit">100Stck.)</span>
            </p>
          </div>
          <div class="value">
            <span class="disclaimer_"
              >* Inkl. MwSt, versandkostenfrei ab CHF 20.</span
            >
            <div class="quantity-wrapper">
              <div class="product-quantity-selector">
                <button
                  class="remove _remove "
                  @click="changeOrderValue('p10170342', '-')"
                >
                  -
                </button>
                <span class="quantity-label _quantity-label">{{
                  product.p10170342
                }}</span>
                <button
                  class="add _add "
                  @click="changeOrderValue('p10170342')"
                >
                  +
                </button>
              </div>
            </div>
            <div class="disclaimer_ bonus-string">
              <div
                class="mobile-tooltip _mobile-tooltip"
                style="display: none;"
              >
                Jeder Kauf einer Delizio Kapselpackung lohnt sich: Sammeln Sie
                On- oder Offline wertvolle Genusspunkte und lösen Sie diese bei
                Ihrer nächsten Online-Bestellung ein.
              </div>
              Für dieses Produkt erhalten Sie
              <span class="_bonus-price">48</span> Punkte
              <span
                class="info-link _reward-tooltip"
                data-container="section"
                title=""
                data-original-title="Jeder Kauf einer Delizio Kapselpackung lohnt sich: Sammeln Sie On- oder Offline wertvolle Genusspunkte und lösen Sie diese bei Ihrer nächsten Online-Bestellung ein."
                >i</span
              >
            </div>
          </div>
        </div>
        <hr />
      </div>
      <div class="miniature-fake-element"></div>
    </article>
    <article
      class="product-miniature coffee-miniature _quantity-selector _size_selector"
      data-product-id="10165503"
    >
      <div class="image-wrapper">
        <a href="https://www.delizio.ch/de/kaffee-tee/kaffee/ristretto-forte">
          <img
            class="img-responsive _product-main-image"
            src="/assets/image/products/Ristretto-Forte_48-ProductImage_65renewable-raw-materialsJPunDHuvKQzKb_200x200@2x.webp"
          />
        </a>
      </div>
      <div class="product-miniature-inner-wrapper">
        <div class="product-name-wrapper">
          <h5 class="product-name">
            <a
              href="https://www.delizio.ch/de/kaffee-tee/kaffee/ristretto-forte"
              >Ristretto Forte</a
            >
          </h5>
          <hr class="hr-cross-sell" />
        </div>
        <div class="partial-attribute-wrapper product-type-wrapper ">
          <span class="label"> Sorte </span>
          <span class="value"> Ristretto </span>
        </div>
        <div class="partial-attribute-wrapper product-cup-size-wrapper">
          <span class="label"> Tassengrösse </span>
          <div class="value product-cup-sizes">
            <span class="item">
              <svg viewBox="0 0 38 50" class="selected">
                <g>
                  <path
                    d="M14.48,41.46h7.67c1.78,0,2.6-2,3-3.94l0.27,0h0c2,0,4-2.09,4.46-4.66,0.46-2.87-.86-5.49-3-5.82a3.13,3.13,0,0,0-1.44.12V26H11.15v8.27S11.15,41.42,14.48,41.46Zm11.33-12a1.18,1.18,0,0,1,.64-0.25h0.12a1.08,1.08,0,0,1,.64.47A4.38,4.38,0,0,1,26.34,35a1.21,1.21,0,0,1-.66.25H25.61l-0.11,0a0.41,0.41,0,0,1-.18-0.05h0l0.13-5.34A2.09,2.09,0,0,1,25.81,29.49ZM13.38,28.24h9.89v6c0,0.16,0,.33,0,0.49H14.7v1.87A11.37,11.37,0,0,0,15,39.23H14.48c-0.45-.31-1.1-2.66-1.11-4.94v-6Z"
                  ></path>
                  <path
                    d="M4.44,44v1.33h0a1.9,1.9,0,0,0,1.76,2H30.28a1.9,1.9,0,0,0,1.76-2h0V44H4.44Z"
                  ></path>
                </g>
              </svg>
            </span>
            <span class="item">
              <svg viewBox="0 0 38 50">
                <g>
                  <path
                    d="M14.56,41.46h7.67c1.78,0,2.6-2,3-3.94l0.27,0h0c2,0,4-2.09,4.46-4.66,0.46-2.87-.86-5.49-3-5.82a3.13,3.13,0,0,0-1.44.12V26H11.23v8.27S11.23,41.42,14.56,41.46Zm11.33-12a1.18,1.18,0,0,1,.64-0.25h0.12a1.08,1.08,0,0,1,.64.47A4.38,4.38,0,0,1,26.42,35a1.21,1.21,0,0,1-.66.25H25.68l-0.11,0a0.41,0.41,0,0,1-.18-0.05h0l0.13-5.34A2.09,2.09,0,0,1,25.89,29.49ZM13.45,28.24h9.89v2c0,0.16,0,.33,0,0.49H14.78v5.87a11.37,11.37,0,0,0,.28,2.57H14.56c-0.45-.31-1.1-2.66-1.11-4.94v-6Z"
                  ></path>
                  <path
                    d="M4.52,44v1.33h0a1.9,1.9,0,0,0,1.76,2H30.36a1.9,1.9,0,0,0,1.76-2h0V44H4.52Z"
                  ></path>
                </g>
              </svg>
            </span>
            <span class="item">
              <svg viewBox="0 0 38 50">
                <g>
                  <path
                    d="M34.31,23.53a4.4,4.4,0,0,0-3.19-2.61,4.08,4.08,0,0,0-2.43.39V19.45H8.29V31.23S8,41.47,13,41.46H24c3,0,4.1-3.63,4.51-6.57h0a3.74,3.74,0,0,0,.57,0c2.7,0,5.36-2.81,5.91-6.26A8.66,8.66,0,0,0,34.31,23.53Zm-23.8,7.76V21.68h16V25H12.07v9.37a13,13,0,0,0,.79,4.86,1,1,0,0,1-.63-0.34C11,37.56,10.46,33.65,10.51,31.29Zm22.23-3a6.06,6.06,0,0,1-2.1,3.87,2.1,2.1,0,0,1-1.2.45l-0.28,0a0.92,0.92,0,0,1-.47-0.15c0-.74,0-1.21,0-1.21V24.15a3.77,3.77,0,0,1,.65-0.62,2,2,0,0,1,1.42-.42A2,2,0,0,1,32,24,6,6,0,0,1,32.74,28.31Z"
                  ></path>
                  <path
                    d="M2.92,45.36h0a2,2,0,0,0,2,2H32.1a2,2,0,0,0,2-2h0V44H2.92v1.33Z"
                  ></path>
                </g>
              </svg>
            </span>
            <span class="item">
              <svg viewBox="0 0 38 50" class="selected">
                <g>
                  <path
                    d="M9,34.6c0.2,2.6,1,6.8,4.4,6.8h10.1c3.4,0,4.3-4.3,4.5-6.8l1-19.4H8L9,34.6z M26.8,17.3l-0.2,4.6H11.9l0.4,8 h13.8L26,34.6c-0.1,1.3-0.5,5-2.4,5H13.4c-1.9,0-2.3-3.6-2.4-5l-0.2-4.7l-0.6-12.6H26.8z"
                  ></path>
                  <path
                    d="M3.7,44v1.3c-0.1,1,0.7,1.9,1.8,2h26.1c1-0.1,1.8-1,1.8-2V44H3.7z"
                  ></path>
                  <path
                    d="M10,13.6c0-0.1,0-0.4,0.1-0.8c0.1-0.4,0.2-0.8,0.6-1.1c0.5-0.4,1.3,0,1.7,0.2c0.5,0.3,0.8,0.2,1,0.1 c0.2-0.1,0.5-0.3,0.7-0.7c0.3-0.9,0.9-2,2.4-2.3c2-0.4,3.4,0.8,3.5,0.8c0.3,0.2,0.7,0.3,1,0.2c0.1,0,2.2-0.7,2.9,0.6 c0.2,0.4,0.6,0.6,1,0.5c0,0,0.8,0,1.4,0.5c0.4,0.4,0.6,1,0.6,1.9h2c0-1.5-0.4-2.6-1.3-3.4c-0.8-0.7-1.7-0.9-2.3-1 c-1.2-1.5-3.2-1.5-4.5-1.2C20,7.4,18.3,6.5,16,7c-1.5,0.3-2.8,1.3-3.5,2.7c-1.1-0.4-2.2-0.3-3,0.3c-0.7,0.5-1.2,1.3-1.4,2.4 C8,12.9,8,13.4,8,13.6H10z"
                  ></path>
                </g>
              </svg>
            </span>
          </div>
        </div>
        <hr class="hr-after-product-cup-size" />
        <div class="partial-attribute-wrapper product-intensity-wrapper">
          <span class="label"> Stärkegrad </span>
          <div class="value">
            <span>1</span> <span>2</span> <span>3</span> <span>4</span>
            <span class="selected">5</span>
          </div>
        </div>
        <hr class="hr-after-product-intensity" />
        <div class="partial-attribute-wrapper coffee-product-size-wrapper">
          <p
            class="label no-editable "
            v-html="$t('machineInformation.productPackage')"
          ></p>
          <div class="value">
            <button
              class="btn-package-size _size-selector-trigger selected"
              data-product-id="10168380"
              data-price="19.80"
              data-old-price="0"
              data-referenceprice="41.25"
              data-bonus-price="48"
              data-size-selector-index="1"
            >
              48
            </button>
          </div>
        </div>
        <hr />
        <div class="partial-attribute-wrapper product-price-wrapper">
          <div class="label">
            <p class="price">
              <span class="_product-price">19.80</span>
              <span class="_product-old-price-target old-price" hidden="true"
                >0</span
              >
            </p>
            <p class="price-tag _product-price-tag"></p>
            <p class="price base-price">
              <span class="product-base-price"
                >(CHF <span class="product-base-price-tag">41.25</span> /
              </span>
              <span class="product-base-unit">100Stck.)</span>
            </p>
          </div>
          <div class="value">
            <span class="disclaimer_"
              >* Inkl. MwSt, versandkostenfrei ab CHF 20.</span
            >
            <div class="quantity-wrapper">
              <div class="product-quantity-selector">
                <button
                  class="remove _remove "
                  @click="changeOrderValue('p10168380', '-')"
                >
                  -
                </button>
                <span class="quantity-label _quantity-label">{{
                  product.p10168380
                }}</span>
                <button
                  class="add _add "
                  @click="changeOrderValue('p10168380')"
                >
                  +
                </button>
              </div>
            </div>
            <div class="disclaimer_ bonus-string">
              <div
                class="mobile-tooltip _mobile-tooltip"
                style="display: none;"
              >
                Jeder Kauf einer Delizio Kapselpackung lohnt sich: Sammeln Sie
                On- oder Offline wertvolle Genusspunkte und lösen Sie diese bei
                Ihrer nächsten Online-Bestellung ein.
              </div>
              Für dieses Produkt erhalten Sie
              <span class="_bonus-price">48</span> Punkte
              <span
                class="info-link _reward-tooltip"
                data-container="section"
                title=""
                data-original-title="Jeder Kauf einer Delizio Kapselpackung lohnt sich: Sammeln Sie On- oder Offline wertvolle Genusspunkte und lösen Sie diese bei Ihrer nächsten Online-Bestellung ein."
                >i</span
              >
            </div>
          </div>
        </div>
        <hr />
      </div>
      <div class="miniature-fake-element"></div>
    </article>
    <article
      class="product-miniature coffee-miniature _quantity-selector _size_selector"
      data-product-id="10171008"
    >
      <div class="image-wrapper">
        <a
          href="https://www.delizio.ch/de/kaffee-tee/kaffee/lungo-decaffeinato"
        >
          <img
            class="img-responsive _product-main-image"
            src="/assets/image/products/Lungo-Decaff_48-ProductImage_65renewable-raw-materials815i8X46OgRqi_200x200@2x.webp"
          />
        </a>
      </div>
      <div class="product-miniature-inner-wrapper">
        <div class="product-name-wrapper">
          <h5 class="product-name">
            <a
              href="https://www.delizio.ch/de/kaffee-tee/kaffee/lungo-decaffeinato"
              >Lungo Decaffeinato</a
            >
          </h5>
          <hr class="hr-cross-sell" />
        </div>
        <div class="partial-attribute-wrapper product-type-wrapper ">
          <span class="label"> Sorte </span> <span class="value"> Lungo </span>
        </div>
        <div class="partial-attribute-wrapper product-cup-size-wrapper">
          <span class="label"> Tassengrösse </span>
          <div class="value product-cup-sizes">
            <span class="item">
              <svg viewBox="0 0 38 50">
                <g>
                  <path
                    d="M14.48,41.46h7.67c1.78,0,2.6-2,3-3.94l0.27,0h0c2,0,4-2.09,4.46-4.66,0.46-2.87-.86-5.49-3-5.82a3.13,3.13,0,0,0-1.44.12V26H11.15v8.27S11.15,41.42,14.48,41.46Zm11.33-12a1.18,1.18,0,0,1,.64-0.25h0.12a1.08,1.08,0,0,1,.64.47A4.38,4.38,0,0,1,26.34,35a1.21,1.21,0,0,1-.66.25H25.61l-0.11,0a0.41,0.41,0,0,1-.18-0.05h0l0.13-5.34A2.09,2.09,0,0,1,25.81,29.49ZM13.38,28.24h9.89v6c0,0.16,0,.33,0,0.49H14.7v1.87A11.37,11.37,0,0,0,15,39.23H14.48c-0.45-.31-1.1-2.66-1.11-4.94v-6Z"
                  ></path>
                  <path
                    d="M4.44,44v1.33h0a1.9,1.9,0,0,0,1.76,2H30.28a1.9,1.9,0,0,0,1.76-2h0V44H4.44Z"
                  ></path>
                </g>
              </svg>
            </span>
            <span class="item">
              <svg viewBox="0 0 38 50" class="selected">
                <g>
                  <path
                    d="M14.56,41.46h7.67c1.78,0,2.6-2,3-3.94l0.27,0h0c2,0,4-2.09,4.46-4.66,0.46-2.87-.86-5.49-3-5.82a3.13,3.13,0,0,0-1.44.12V26H11.23v8.27S11.23,41.42,14.56,41.46Zm11.33-12a1.18,1.18,0,0,1,.64-0.25h0.12a1.08,1.08,0,0,1,.64.47A4.38,4.38,0,0,1,26.42,35a1.21,1.21,0,0,1-.66.25H25.68l-0.11,0a0.41,0.41,0,0,1-.18-0.05h0l0.13-5.34A2.09,2.09,0,0,1,25.89,29.49ZM13.45,28.24h9.89v2c0,0.16,0,.33,0,0.49H14.78v5.87a11.37,11.37,0,0,0,.28,2.57H14.56c-0.45-.31-1.1-2.66-1.11-4.94v-6Z"
                  ></path>
                  <path
                    d="M4.52,44v1.33h0a1.9,1.9,0,0,0,1.76,2H30.36a1.9,1.9,0,0,0,1.76-2h0V44H4.52Z"
                  ></path>
                </g>
              </svg>
            </span>
            <span class="item">
              <svg viewBox="0 0 38 50" class="selected">
                <g>
                  <path
                    d="M34.31,23.53a4.4,4.4,0,0,0-3.19-2.61,4.08,4.08,0,0,0-2.43.39V19.45H8.29V31.23S8,41.47,13,41.46H24c3,0,4.1-3.63,4.51-6.57h0a3.74,3.74,0,0,0,.57,0c2.7,0,5.36-2.81,5.91-6.26A8.66,8.66,0,0,0,34.31,23.53Zm-23.8,7.76V21.68h16V25H12.07v9.37a13,13,0,0,0,.79,4.86,1,1,0,0,1-.63-0.34C11,37.56,10.46,33.65,10.51,31.29Zm22.23-3a6.06,6.06,0,0,1-2.1,3.87,2.1,2.1,0,0,1-1.2.45l-0.28,0a0.92,0.92,0,0,1-.47-0.15c0-.74,0-1.21,0-1.21V24.15a3.77,3.77,0,0,1,.65-0.62,2,2,0,0,1,1.42-.42A2,2,0,0,1,32,24,6,6,0,0,1,32.74,28.31Z"
                  ></path>
                  <path
                    d="M2.92,45.36h0a2,2,0,0,0,2,2H32.1a2,2,0,0,0,2-2h0V44H2.92v1.33Z"
                  ></path>
                </g>
              </svg>
            </span>
            <span class="item">
              <svg viewBox="0 0 38 50">
                <g>
                  <path
                    d="M9,34.6c0.2,2.6,1,6.8,4.4,6.8h10.1c3.4,0,4.3-4.3,4.5-6.8l1-19.4H8L9,34.6z M26.8,17.3l-0.2,4.6H11.9l0.4,8 h13.8L26,34.6c-0.1,1.3-0.5,5-2.4,5H13.4c-1.9,0-2.3-3.6-2.4-5l-0.2-4.7l-0.6-12.6H26.8z"
                  ></path>
                  <path
                    d="M3.7,44v1.3c-0.1,1,0.7,1.9,1.8,2h26.1c1-0.1,1.8-1,1.8-2V44H3.7z"
                  ></path>
                  <path
                    d="M10,13.6c0-0.1,0-0.4,0.1-0.8c0.1-0.4,0.2-0.8,0.6-1.1c0.5-0.4,1.3,0,1.7,0.2c0.5,0.3,0.8,0.2,1,0.1 c0.2-0.1,0.5-0.3,0.7-0.7c0.3-0.9,0.9-2,2.4-2.3c2-0.4,3.4,0.8,3.5,0.8c0.3,0.2,0.7,0.3,1,0.2c0.1,0,2.2-0.7,2.9,0.6 c0.2,0.4,0.6,0.6,1,0.5c0,0,0.8,0,1.4,0.5c0.4,0.4,0.6,1,0.6,1.9h2c0-1.5-0.4-2.6-1.3-3.4c-0.8-0.7-1.7-0.9-2.3-1 c-1.2-1.5-3.2-1.5-4.5-1.2C20,7.4,18.3,6.5,16,7c-1.5,0.3-2.8,1.3-3.5,2.7c-1.1-0.4-2.2-0.3-3,0.3c-0.7,0.5-1.2,1.3-1.4,2.4 C8,12.9,8,13.4,8,13.6H10z"
                  ></path>
                </g>
              </svg>
            </span>
          </div>
        </div>
        <hr class="hr-after-product-cup-size" />
        <div class="partial-attribute-wrapper product-intensity-wrapper">
          <span class="label"> Stärkegrad </span>
          <div class="value">
            <span>1</span> <span>2</span> <span class="selected">3</span>
            <span>4</span> <span>5</span>
          </div>
        </div>
        <hr class="hr-after-product-intensity" />
        <div class="partial-attribute-wrapper coffee-product-size-wrapper">
          <p
            class="label no-editable "
            v-html="$t('machineInformation.productPackage')"
          ></p>
          <div class="value">
            <button
              class="btn-package-size _size-selector-trigger selected"
              data-product-id="10171008"
              data-price="19.80"
              data-old-price="0"
              data-referenceprice="41.25"
              data-bonus-price="48"
              data-size-selector-index="0"
            >
              48
            </button>
          </div>
        </div>
        <hr />
        <div class="partial-attribute-wrapper product-price-wrapper">
          <div class="label">
            <p class="price">
              <span class="_product-price">19.80</span>
              <span class="_product-old-price-target old-price" hidden="true"
                >0</span
              >
            </p>
            <p class="price-tag _product-price-tag"></p>
            <p class="price base-price">
              <span class="product-base-price"
                >(CHF <span class="product-base-price-tag">41.25</span> /
              </span>
              <span class="product-base-unit">100Stck.)</span>
            </p>
          </div>
          <div class="value">
            <span class="disclaimer_"
              >* Inkl. MwSt, versandkostenfrei ab CHF 20.</span
            >
            <div class="quantity-wrapper">
              <div class="product-quantity-selector">
                <button
                  class="remove _remove "
                  @click="changeOrderValue('p10171008', '-')"
                >
                  -
                </button>
                <span class="quantity-label _quantity-label">{{
                  product.p10171008
                }}</span>
                <button
                  class="add _add "
                  @click="changeOrderValue('p10171008')"
                >
                  +
                </button>
              </div>
            </div>
            <div class="disclaimer_ bonus-string">
              <div
                class="mobile-tooltip _mobile-tooltip"
                style="display: none;"
              >
                Jeder Kauf einer Delizio Kapselpackung lohnt sich: Sammeln Sie
                On- oder Offline wertvolle Genusspunkte und lösen Sie diese bei
                Ihrer nächsten Online-Bestellung ein.
              </div>
              Für dieses Produkt erhalten Sie
              <span class="_bonus-price">48</span> Punkte
              <span
                class="info-link _reward-tooltip"
                data-container="section"
                title=""
                data-original-title="Jeder Kauf einer Delizio Kapselpackung lohnt sich: Sammeln Sie On- oder Offline wertvolle Genusspunkte und lösen Sie diese bei Ihrer nächsten Online-Bestellung ein."
                >i</span
              >
            </div>
          </div>
        </div>
        <hr />
      </div>
      <div class="miniature-fake-element"></div>
    </article>
    <div class="error" v-html="$t('machineInformation.productMessage')"></div>
  </section>
</template>

<script>
export default {
  name: "ProductView",
  props: {
    display: Boolean,
  },
  updated() {
    this.$nextTick(function() {
      if (this.data) {
        //adasd
      }
    });
  },
  watch: {
    display(val) {
      console.log(val);
      this.tabActive = val;
    },
  },
  data() {
    return {
      product: {
        p10176082: 0,
        p10168322: 0,
        p10171008: 0,
        p10170342: 0,
        p10170588: 0,
        p10168380: 0,
      },
      productTotal: 0,
      tabActive: this.display || false,
    };
  },
  methods: {
    changeOrderValue(product, type = "+") {
      if (type == "+") {
        if (this.productTotal + 1 >= 3 || this.product[product] + 1 >= 3)
          return;
        this.product[product]++;
        this.productTotal++;
      } else {
        if (this.productTotal - 1 < 0 || this.product[product] - 1 < 0) return;
        this.product[product]--;
        this.productTotal--;
      }
      console.log(this.product);
      this.$emit("update", JSON.stringify(this.product));
    },
  },
};
</script>

<style lang="scss">
.error {
  flex: 0 0 100%;
}
</style>

<template>
  <div class="warranty-explanation">
    <h3 v-html="$t('serialExplanation.headline')"></h3>
    <p v-html="$t('serialExplanation.text')"></p>
    <div class="warranty-explanation-image-wrapper">
      <div
        class="warranty-explanation-image"
        v-for="(img, index) in Object.keys(
          $i18n.messages[$i18n.locale].serialExplanation.img
        )"
        :key="index"
      >
        <img :src="'/assets/image/' + $t(`serialExplanation.img[${img}]`)" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SerialExplanation",
};
</script>

<style lang="scss"></style>

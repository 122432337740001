<template>
  <div class="serial-number-input-section" v-if="!this.validSerial">
    <div class="serial-number-machine-section">
      <p class="machine-text" v-html="$t('serialPicker.machine-text')"></p>
    </div>
    <div class="serial-number-input-wrapper">
      <input
        type="text"
        name="serial-number-input"
        id="serial-number-input"
        v-model="serialNumber"
        maxlength="18"
        minlength="18"
      />
      <button id="serial-number-button" @click="checkSerial">
        <div id="serial-number-loader" class="loader" v-if="buttonLoader"></div>
        <span
          id="serial-number-text"
          v-if="!buttonLoader"
          v-html="$t('serialPicker.checkButton')"
        ></span>
      </button>
    </div>
  </div>
  <p
    class="warning"
    id="warning"
    v-if="serialError"
    v-html="$t('serialPicker.lengthError')"
  ></p>
  <MachineInformation
    :display="validSerial"
    :machineData="dummyCoffee"
    :serialNumber="serialNumber"
  ></MachineInformation>
  <SerialExplanation v-if="!this.validSerial" />
  <!-- <BonusProgram v-if="!this.validSerial" /> -->
</template>

<script>
import MachineInformation from "./MachineInformation.vue";
import SerialExplanation from "./SerialExplanation.vue";
// import BonusProgram from "./BonusProgram.vue";

export default {
  name: "SerialPicker",
  components: {
    MachineInformation,
    SerialExplanation,
    // BonusProgram,
  },
  data() {
    return {
      validSerial: false,
      serialNumber: "",
      currentSerial: "",
      serialError: false,
      buttonLoader: false,
      dummyCoffee: "",
    };
  },
  methods: {
    checkSerial() {
      this.buttonLoader = true;
      if (this.serialNumber.length !== 18) {
        this.serialError = true;
        this.buttonLoader = false;
        this.validSerial = false;
        return;
      }
      if (this.serialNumber === this.currentSerial) {
        this.buttonLoader = false;
        return;
      }
      this.currentSerial = this.serialNumber;
      fetch(
        this.apiUrl +
          "api/validateserialnumber?serialNumber=" +
          this.serialNumber,
        {
          method: "GET",
        }
      )
        .then((response) => response.json())
        .then((response) => {
          if (response.statusCode == "10001") {
            console.log("validation error");
            this.validSerial = false;
            this.serialError = true;
            this.buttonLoader = false;
            return;
          }
          if (response.statusCode == "10000") {
            this.dummyCoffee = response.data;
            this.serialError = false;
            this.buttonLoader = false;
            this.validSerial = true;
            return;
          }
        })
        .catch((e) => console.error(e));

      //Test with simulated Timeout
      // setTimeout(() => {
      //   if (
      //     this.serialNumber.length !== 18 &&
      //     this.serialNumber === this.currentSerial
      //   ) {
      //     this.serialError = true;
      //     this.buttonLoader = false;
      //     return;
      //   }

      //   if (this.serialNumber == "123456789012345678") {
      //     this.dummyCoffee =
      //       '{"authorized":true,"models":{"Delizio Carina":{"model_number":"268","name":"Delizio Carina","colors":{"Heart Red / Shell White":{"colorname":"Heart Red / Shell White","colorhex":"#b90064","mgb_article_number":"717478500000","fust_article_number":"10107026","model_image":"https://www.delizio.ch/media/image/f7/7d/25/DZ-Carina-RedDotDesignAward-R1.png"},"Midnight Black":{"colorname":"Midnight Black","colorhex":"#070707","mgb_article_number":"717478400000","fust_article_number":"10105472","model_image":"https://www.delizio.ch/media/image/c5/b3/9d/01-DZ-Adjust-all-machine-images-to-one-size-Carina-Black.png"},"Swiss Edition":{"colorname":"Swiss Edition","colorhex":"#cf1d46","mgb_article_number":"717483800000","fust_article_number":"","model_image":"https://www.delizio.ch/media/image/fa/d2/d6/40731149-bd1a0a90-6430-11e8-832e-949685c309b4.png"},"Shell White/Midnight Black":{"colorname":"Shell White/Midnight Black","colorhex":"#070707","mgb_article_number":"717478600000","fust_article_number":"","model_image":"https://www.delizio.ch/media/image/c5/b3/9d/01-DZ-Adjust-all-machine-images-to-one-size-Carina-Black.png"}}}}}';
      //   } else {
      //     this.dummyCoffee =
      //       '{"authorized":true,"models":{"Delizio Compact One II":{"model_number":"267","name":"Delizio Compact One II1","colors":{"Cream White":{"colorname":"Cream White","colorhex":"#fff2d4","mgb_article_number":"717461900000","fust_article_number":"10090527","model_image":"https://www.delizio.ch/media/image/b6/2b/93/01-DZ-Adjust-all-machine-images-to-one-size-Compact-One-II-White.png"},"Graphite Black":{"colorname":"Graphite Black","colorhex":"#262423 ","mgb_article_number":"717462100000","fust_article_number":"10090705","model_image":"https://www.delizio.ch/media/image/23/1f/7b/01-DZ-Adjust-all-machine-images-to-one-size-Compact-One-II-Black.png"},"Glossy Red":{"colorname":"Glossy Red","colorhex":"#d8161a","mgb_article_number":"717462000000","fust_article_number":"10090658","model_image":"https://www.delizio.ch/media/image/b5/f6/21/01-DZ-Adjust-all-machine-images-to-one-size-Compact-One-II-Red.png"}}}}}';
      //   }
      //   this.buttonLoader = false;
      //   this.validSerial = true;
      // }, 2000); //Simulate loading
    },
  },
};
</script>

<style lang="scss"></style>

<template>
  <div class="warranty-header">
    <div class="warranty-header-content">
      <h1 v-html="$t('warrantyHeader.headline')"></h1>
      <p v-html="$t('warrantyHeader.text')"></p>
    </div>
    <img
      class="warranty-header-image"
      :src="$t('warrantyHeader.desktopImg')"
      alt="warranty-header"
    />
    <img
      class="warranty-header-image-mobile"
      :src="$t('warrantyHeader.mobileImg')"
      alt="warranty-header"
    />
  </div>
</template>

<script>
export default {
  name: "WarrantyHeader",
};
</script>

<style></style>

<template>
  <CookieCompliance />
  <CampaignEndPopup />
  <Navigation />
  <WarrantyHeader />
  <TextBox />
  <SerialPicker />
  <MachineInformation />
  <!-- <SerialExplanation /> -->
  <!-- <BonusProgram /> -->
  <!-- <NewForm /> -->
  <!-- <MainArea /> -->
  <Faq />
  <TermsAndConditions />

  <Footer />
</template>

<script>
import Navigation from "../components/Navigation.vue";
import WarrantyHeader from "../components/WarrantyHeader.vue";
import TextBox from "../components/TextBox.vue";
import CampaignEndPopup from "../components/CampaignEndPopup.vue";
import SerialPicker from "../components/SerialPicker.vue";

// import SerialExplanation from "../components/SerialExplanation.vue";
// import BonusProgram from "../components/BonusProgram.vue";
// import MainArea from '../components/MainArea.vue'
import TermsAndConditions from "../components/TermsAndConditions.vue";
import Faq from "../components/Faq.vue";
// import Popup from "../components/Popup.vue";
import Footer from "../components/Footer.vue";
import CookieCompliance from "../components/CookieCompliance.vue";
import { computed, reactive } from "vue";
import { useHead } from "@vueuse/head";
import i18n from "../i18n";
// import NewForm from '../components/NewForm.vue'

export default {
  name: "Home",
  components: {
    WarrantyHeader,
    Navigation,
    // MainArea,
    TermsAndConditions,
    Faq,
    CampaignEndPopup,
    // Popup,
    Footer,
    CookieCompliance,
    TextBox,
    SerialPicker,
    // SerialExplanation,
    // BonusProgram,
    // NewForm
  },
  created() {
    console.log(i18n.locale);
    let descr = "";
    let title = "";
    let locale = "";
    let alternativeLang = "";
    if (i18n.locale == "fr") {
      descr =
        "Enregistrez votre nouvelle machine à café Delizio en ligne et recevez 96 capsules de café gratuites ! Période de promotion : du 3 septembre 2024 au 4 novembre 2024. Savourez d’excellents moments café !";
      title = "Delizio - S’enregistrer et savourer";
      locale = "fr_fr";
      alternativeLang = "de";
    } else {
      descr =
        "Registrieren Sie Ihre neue Delizio Kaffeemaschine online und erhalten Sie 96 Kaffeekapseln gratis! Aktionszeitraum: 03.09.2024 - 04.11.2024. Geniessen Sie exzellente Kaffeemomente!";
      title = "Delizio - Registrieren und 96 Kapseln geniessen";
      locale = "de_De";
      alternativeLang = "fr";
    }
    const siteData = reactive({
      robots: "index, follow",
      canonical: this.hostedUrl + "/" + alternativeLang,
      title: title,
      description: descr,
      ogLocale: locale,
      ogType: "website",
      ogUrl: this.hostedUrl + "/",
      ogSiteName: locale,
      ogImage: this.hostedUrl + "/assets/image/ograph.jpg",
      ogImageWidth: "1200",
      ogImageHeight: "1600",
      twitterCard: "summary_large_image",
      twitterImage: this.hostedUrl + "/assets/image/twitter-image.pnh",
      publishedTime: "2024-09-19T09:00:00+00:00",
      appleTouchIcon: this.hostedUrl + "/assets/image/apple-touch-icon.png",
    });

    useHead({
      title: computed(() => siteData.title),
      link: [
        {
          rel: "canonical",
          href: computed(() => siteData.canonical),
        },
        {
          rel: "apple-touch-icon",
          href: computed(() => siteData.appleTouchIcon),
        },
      ],
      meta: [
        {
          name: "robots",
          content: computed(() => siteData.robots),
        },
        {
          name: "description",
          content: computed(() => siteData.description),
        },
        {
          name: "og:locale",
          content: computed(() => siteData.ogLocale),
        },
        {
          name: "og:type",
          content: computed(() => siteData.ogType),
        },
        {
          name: "og:title",
          content: computed(() => siteData.title),
        },
        {
          name: "og:description",
          content: computed(() => siteData.description),
        },
        {
          name: "og:url",
          content: computed(() => siteData.ogUrl),
        },
        {
          name: "og:site_name",
          content: computed(() => siteData.ogSiteName),
        },
        {
          name: "og:image",
          content: computed(() => siteData.ogImage),
        },
        {
          name: "og:image:width",
          content: computed(() => siteData.ogImageWidth),
        },
        {
          name: "og:image:height",
          content: computed(() => siteData.ogImageHeight),
        },
        {
          name: "twitter:card",
          content: computed(() => siteData.twitterCard),
        },
        {
          name: "twitter:title",
          content: computed(() => siteData.title),
        },
        {
          name: "twitter:description",
          content: computed(() => siteData.description),
        },
        {
          name: "twitter:image",
          content: computed(() => siteData.twitterImage),
        },
        {
          name: "article:published_time",
          content: computed(() => siteData.publishedTime),
        },
      ],
    });
  },
};
</script>

<style></style>

<template>
  <div id="popup" v-if="popupDisplay">
    <div class="content">
      <div class="close" @click="closePopup">X</div>
      <h3 v-html="$t('popup.headline')"></h3>
      <div class="innerContent">
        <table>
          <thead>
            <tr>
              <th
                v-for="(headlines, index) in Object.keys(
                  $i18n.messages[$i18n.locale].popup.table.headlines
                )"
                :key="index"
              >
                {{ $t(`popup.table.headlines[${headlines}]`) }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(markets, index) in Object.keys(
                $i18n.messages[$i18n.locale].popup.table.markets
              )"
              :key="index"
            >
              <!-- <td>{{ $t(`popup.table.markets[${markets}].btrb`) }}</td>
              <td>{{ $t(`popup.table.markets[${markets}].gm`) }}</td> -->
              <td>{{ $t(`popup.table.markets[${markets}].flk`) }}</td>
              <td>{{ $t(`popup.table.markets[${markets}].filiale`) }}</td>
              <td>{{ $t(`popup.table.markets[${markets}].strasse`) }}</td>
              <td>{{ $t(`popup.table.markets[${markets}].plz`) }}</td>
              <td>{{ $t(`popup.table.markets[${markets}].ort`) }}</td>
              <td>{{ $t(`popup.table.markets[${markets}].format`) }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "StepItem",
  props: {
    display: Boolean,
  },
  data() {
    return {
      popupDisplay: this.display || false,
    };
  },
  watch: {
    display(val) {
      this.popupDisplay = val;
    },
  },
  methods: {
    closePopup() {
      this.popupDisplay = false;
      window.dispatchEvent(new CustomEvent("closePopup"));
    },
  },
};
</script>

<style></style>

<template>
  <aside id="terms-and-conditions" class="padding-box">
    <div class="row">
      <div class="columns c-12">
        <h2 class="" v-html="$t('terms.headline')"></h2>
        <div class="ui-accordion">
          <div
            class="ui-accordion-header"
            :class="{ open: accordionActive }"
            @click="accordionFunction"
          >
            <p v-html="$t('terms.accordionHeader')"></p>
          </div>
          <div class="ui-accordion-body" :class="{ open: accordionActive }">
            <div v-html="$t('terms.content.intro')"></div>
            <div
              v-html="
                $t('terms.content.block1', {
                  functionstart: `<a id='openList'>`,
                  functionend: '</a>',
                })
              "
            ></div>
            <div v-html="$t('terms.content.block2')"></div>
            <div v-html="$t('terms.content.block3')"></div>
            <div v-html="$t('terms.content.block4')"></div>
            <div v-html="$t('terms.content.block5')"></div>
            <div v-html="$t('terms.content.block6')"></div>
          </div>
        </div>
      </div>
    </div>
  </aside>
  <hr />
  <div class="subs">
    <div class="row">
      <div class="columns c-12">
        <small></small>
      </div>
    </div>
  </div>
  <Popup :display="popup" />
</template>

<script>
import Popup from "./Popup.vue";
export default {
  name: "TearmsAndConditions",
  components: {
    Popup,
  },
  updated() {
    let _this = this;
    document
      .querySelector("#openList")
      .removeEventListener("click", function() {
        _this.popup = true;
      });
    document.querySelector("#openList").addEventListener("click", function() {
      _this.popup = true;
    });
    window.addEventListener("closePopup", function() {
      _this.popup = false;
      console.log(_this.popup);
    });
  },
  data: function() {
    return {
      accordionActive: false,
      popup: false,
    };
  },
  methods: {
    accordionFunction() {
      this.accordionActive = !this.accordionActive;
    },
  },
};
</script>

<style></style>

<template>
  <div class="acris--page-wrap--cookie-permission is--modal" id="acris--page-wrap--cookie-permission" style="display: flex;" v-bind:style="[bannerDisplay ? {'display':'flex'} : {'display':'none'}]">
   <div class="acris-cookie-settings--wrapper">
      <div class="container acris-cookie-settings--container is--active" style="display: inherit; height: 263px;">
         <div class="acris-cookie-settings--content">
            <div class="acris-cookie-settings--groups">
               <div class="cookie-setting--group">
                  <div class="cookie-setting--panel">
                      <div class="cookie-setting--title" @click="changeDisplay('essential')">
                        <span class="title--name" v-html="$t('cookieCompliance.functional-cookies.cookie-setting--title')"></span>
                        <span class="title--icon"><i class="icon--arrow-down"></i></span>
                      </div>
                      <div class="cookie-setting--switch--container">
                        <label class="cookie-setting--switch is--default">
                          <input type="hidden" class="cookie-setting--switch--group--identification" value="functional">
                          <input type="checkbox" checked="checked" data-groupid="1" class="cookie-setting--switch--group" disabled="disabled" >
                          <span class="switch--slider setting-switch-checked"></span>
                          <span class="switch--slider--enabled switch--slider--active" v-html="$t('cookieCompliance.global.switch--slider--active')"></span>
                          <span class="switch--slider--enabled switch--slider--inactive" v-html="$t('cookieCompliance.global.switch--slider--inactive')"></span>
                        </label>
                      </div>
                  </div>
                  <div class="cookie-setting--data" v-bind:style="[cookieDisplay === 'essential' ? {'display':'block'} : {'display':'none'}]">
                     <div class="cookie-setting--data--inner">
                        <p class="cookie-setting--description" v-html="$t('cookieCompliance.functional-cookies.cookie-setting--description')"></p>
                        <div class="cookie-setting--cookies">
                           <!-- <div class="cookie--item">
                              <div class="cookie--title" v-html="$t('cookieCompliance.functional-cookies.cookie--item.1.cookie--title')"></div>
                              <div class="cookie--description" v-html="$t('cookieCompliance.functional-cookies.cookie--item.1.cookie--description')"></div>
                              <input type="hidden" class="cookie-setting--switch--cookie-id" value="session-\d+|session"> <input type="checkbox" hidden="" checked="checked" value="1" class="cookie-setting--switch--group--cookie is-default-cookie"> 
                           </div>
                           <div class="cookie--item">
                              <div class="cookie--title" v-html="$t('cookieCompliance.functional-cookies.cookie--item.2.cookie--title')"></div>
                              <div class="cookie--description" v-html="$t('cookieCompliance.functional-cookies.cookie--item.2.cookie--description')"></div>
                              <input type="hidden" class="cookie-setting--switch--cookie-id" value="sUniqueID"> <input type="checkbox" hidden="" checked="checked" value="1" class="cookie-setting--switch--group--cookie is-default-cookie"> 
                           </div>
                           <div class="cookie--item">
                              <div class="cookie--title" v-html="$t('cookieCompliance.functional-cookies.cookie--item.3.cookie--title')"></div>
                              <div class="cookie--description" v-html="$t('cookieCompliance.functional-cookies.cookie--item.3.cookie--description')"></div>
                              <input type="hidden" class="cookie-setting--switch--cookie-id" value="x-ua-device"> <input type="checkbox" hidden="" checked="checked" value="1" class="cookie-setting--switch--group--cookie is-default-cookie"> 
                           </div>
                           <div class="cookie--item">
                              <div class="cookie--title" v-html="$t('cookieCompliance.functional-cookies.cookie--item.4.cookie--title')"></div>
                              <div class="cookie--description" v-html="$t('cookieCompliance.functional-cookies.cookie--item.4.cookie--description')"></div>
                              <input type="hidden" class="cookie-setting--switch--cookie-id" value="__csrf_token-\d+|invalidate-xcsrf-token|csrf_token"> <input type="checkbox" hidden="" checked="checked" value="1" class="cookie-setting--switch--group--cookie is-default-cookie"> 
                           </div>
                           <div class="cookie--item">
                              <div class="cookie--title" v-html="$t('cookieCompliance.functional-cookies.cookie--item.5.cookie--title')"></div>
                              <div class="cookie--description" v-html="$t('cookieCompliance.functional-cookies.cookie--item.5.cookie--description')"></div>
                              <input type="hidden" class="cookie-setting--switch--cookie-id" value="slt"> <input type="checkbox" hidden="" checked="checked" value="1" class="cookie-setting--switch--group--cookie is-default-cookie"> 
                           </div>
                           <div class="cookie--item">
                              <div class="cookie--title" v-html="$t('cookieCompliance.functional-cookies.cookie--item.6.cookie--title')"></div>
                              <div class="cookie--description" v-html="$t('cookieCompliance.functional-cookies.cookie--item.6.cookie--description')"></div>
                              <input type="hidden" class="cookie-setting--switch--cookie-id" value="nocache|shop|currency|x-cache-context-hash"> <input type="checkbox" hidden="" checked="checked" value="1" class="cookie-setting--switch--group--cookie is-default-cookie"> 
                           </div>
                           <div class="cookie--item">
                              <div class="cookie--title" v-html="$t('cookieCompliance.functional-cookies.cookie--item.7.cookie--title')"></div>
                              <div class="cookie--description" v-html="$t('cookieCompliance.functional-cookies.cookie--item.7.cookie--description')"></div>
                              <input type="hidden" class="cookie-setting--switch--cookie-id" value="testcookie"> <input type="checkbox" hidden="" checked="checked" value="1" class="cookie-setting--switch--group--cookie is-default-cookie"> 
                           </div> -->
                           <div class="cookie--item">
                              <div class="cookie--title" v-html="$t('cookieCompliance.functional-cookies.cookie--item.8.cookie--title')"></div>
                              <div class="cookie--description" v-html="$t('cookieCompliance.functional-cookies.cookie--item.8.cookie--description')"></div>
                              <input type="hidden" class="cookie-setting--switch--cookie-id" value="allowCookie|cookieDeclined|acris_cookie_acc|cookiePreferences"> <input type="checkbox" hidden="" checked="checked" value="1" class="cookie-setting--switch--group--cookie is-default-cookie"> 
                           </div>
                           <!-- <div class="cookie--item">
                              <div class="cookie--title" v-html="$t('cookieCompliance.functional-cookies.cookie--item.9.cookie--title')"></div>
                              <div class="cookie--description" v-html="$t('cookieCompliance.functional-cookies.cookie--item.9.cookie--description')"></div>
                              <input type="hidden" class="cookie-setting--switch--cookie-id" value="acris_cookie_landing_page|acris_cookie_referrer"> <input type="checkbox" hidden="" checked="checked" value="1" class="cookie-setting--switch--group--cookie is-default-cookie"> 
                           </div>
                           <div class="cookie--item">
                              <div class="cookie--title" v-html="$t('cookieCompliance.functional-cookies.cookie--item.10.cookie--title')"></div>
                              <div class="cookie--description" v-html="$t('cookieCompliance.functional-cookies.cookie--item.10.cookie--description')"></div>
                              <input type="hidden" class="cookie-setting--switch--cookie-id" value="acris_cookie_first_activated"> <input type="checkbox" hidden="" checked="checked" value="1" class="cookie-setting--switch--group--cookie is-default-cookie"> 
                           </div>
                           <div class="cookie--item">
                              <div class="cookie--title" v-html="$t('cookieCompliance.functional-cookies.cookie--item.11.cookie--title')"></div>
                              <div class="cookie--description" v-html="$t('cookieCompliance.functional-cookies.cookie--item.11.cookie--description')"></div>
                              <input type="hidden" class="cookie-setting--switch--cookie-id" value="__csrf_token-\d |invalidate-xcsrf-token|csrf_token"> <input type="checkbox" hidden="" checked="checked" value="1" class="cookie-setting--switch--group--cookie"> 
                           </div> -->
                        </div>
                     </div>
                  </div>
               </div>
               <div class="cookie-setting--group is--not-functional">
                  <div class="cookie-setting--panel">
                    <div class="cookie-setting--title" @click="changeDisplay('tracking')">
                       <span class="title--name" v-html="$t('cookieCompliance.tracking-cookies.cookie-setting--title')"></span>
                       <span class="title--icon"><i class="icon--arrow-down"></i></span>
                    </div>
                    <div class="cookie-setting--switch--container"> 
                         <label class="cookie-setting--switch"> 
                             <input type="hidden" class="cookie-setting--switch--group--identification" value="tracking" > 
                             <input type="checkbox" class="cookie-setting--switch--group" :value="cookieComplianceValue.tracking.enabled">
                             <span v-bind:class="[cookieComplianceValue.tracking.enabled ? 'switch--slider setting-switch-checked' : 'switch--slider setting-switch-unchecked']" @click="changeMainValue()"></span> 
                             <span class="switch--slider--enabled switch--slider--active" v-html="$t('cookieCompliance.global.switch--slider--active')"></span> 
                             <span class="switch--slider--enabled switch--slider--inactive" v-html="$t('cookieCompliance.global.switch--slider--inactive')"></span> 
                          </label> 
                    </div>
                  </div>
                  <div class="cookie-setting--data" v-bind:style="[cookieDisplay ==='tracking' ? {'display':'block'} : {'display':'none'}]">
                     <div class="cookie-setting--data--inner">
                        <p class="cookie-setting--description" v-html="$t('cookieCompliance.tracking-cookies.cookie-setting--description')"></p>
                        <div class="cookie-setting--cookies">
                           <div class="cookie--item">
                              <div class="cookie--title" v-html="$t('cookieCompliance.tracking-cookies.cookie--item.1.cookie--title')"></div>
                              <div class="cookie--description" v-html="$t('cookieCompliance.tracking-cookies.cookie--item.1.cookie--description')"></div>
                              <div class="cookie-setting--switch--container"> 
                                  <label class="cookie-setting--switch is--disabled" > 
                                      <input type="hidden" class="cookie-setting--switch--cookie-id" value="_ga|_gid|_gat_.+|_dc_gtm_UA-.+|ga-disable-UA-.+|__utm(a|b|c|d|t|v|x|z)|_gat|_gac.*"> 
                                      <input type="checkbox" class="cookie-setting--switch--group cookie-setting--switch--group--cookie" v-model="cookieComplianceValue.tracking.googleAnalytics" :disabled="cookieComplianceValue.tracking.enabled"> 
                                      <span v-bind:class="[cookieComplianceValue.tracking.googleAnalytics ? 'switch--slider setting-switch-checked' : 'switch--slider setting-switch-unchecked']" @click="changeSubValue('tracking','googleAnalytics')"></span> 
                                      <span class="switch--slider--enabled switch--slider--active" v-html="$t('cookieCompliance.global.switch--slider--active')"></span> 
                                      <span class="switch--slider--enabled switch--slider--inactive" v-html="$t('cookieCompliance.global.switch--slider--inactive')"></span> 
                                    </label> 
                                </div>
                           </div>
                           <div class="cookie--item">
                              <div class="cookie--title" v-html="$t('cookieCompliance.tracking-cookies.cookie--item.2.cookie--title')"></div>
                              <div class="cookie--description" v-html="$t('cookieCompliance.tracking-cookies.cookie--item.2.cookie--description')"></div>
                              <div class="cookie-setting--switch--container"> 
                                  <label class="cookie-setting--switch is--disabled" @click="changeSubValue('tracking','googleTagManager')"> 
                                      <input type="hidden" class="cookie-setting--switch--cookie-id" value="_dc_gtm_UA-.+|__utm(a|b|c|d|t|v|x|z)"> 
                                      <input type="checkbox" class="cookie-setting--switch--group cookie-setting--switch--group--cookie" v-model="cookieComplianceValue.tracking.googleTagManager" :disabled="cookieComplianceValue.tracking.enabled"> 
                                      <span v-bind:class="[cookieComplianceValue.tracking.googleTagManager ? 'switch--slider setting-switch-checked' : 'switch--slider setting-switch-unchecked']" ></span> 
                                      <span class="switch--slider--enabled switch--slider--active" v-html="$t('cookieCompliance.global.switch--slider--active')"></span> 
                                      <span class="switch--slider--enabled switch--slider--inactive" v-html="$t('cookieCompliance.global.switch--slider--inactive')"></span> 
                                    </label> 
                                </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
      <div class="container cookie-permission--container has--third-btn has--accept-all-btn">
          <div class="cookie-permission--content">
            <div class="cookie-permission--content--border"></div>
            <div v-html="$t('cookieCompliance.global.bigCookieText')"></div>
          </div>
          <div class="cookie-permission--button">
            <button type="button" id="cookie-permission--deny-button" class="cookie-permission--deny-button cookie-permission--button--button btn is--default is--large is--center" v-html="$t('cookieCompliance.global.cookie-permission--deny-button')"></button>
            <button type="button" id="cookie-permission--accept-button" class="cookie-permission--accept-button cookie-permission--button--button btn primary is--large is--center" @click="acceptChoosen()" v-html="$t('cookieCompliance.global.cookie-permission--accept-button')"></button>
            <button type="button" id="cookie-permission--accept-all-button" class="cookie-permission--accept-all-button cookie-permission--button--button btn primary is--large is--center ui-btn" @click="acceptAll()" v-html="$t('cookieCompliance.global.cookie-permission--accept-all-button')"></button>
          </div>
          <div class="cookie-permission--footer">
            <a :href="$t('cookieCompliance.global.cookie-permission--imprint-link')" target="_blank" rel="noopener noreferrer">
              <i class="icon--minus3"></i>
              <span v-html="$t('cookieCompliance.global.cookie-permission--imprint')"></span>
            </a>
          </div>
      </div>
   </div>
</div>
</template>

<script>


 export default ({
        name: 'CookieCompliance',
        data() {
            return {
                bannerDisplay:false,
                cookieDisplay:'',
                cookieComplianceValue: {
                    essential:{
                        enabled:true
                    },
                    tracking:{
                        enabled:false,
                        googleTagManager:false,
                        googleAnalytics:false
                    }
                }
            }
        },
        created: function () {
            if(!localStorage.getItem('cookie')){
                this.bannerDisplay = true
            }
            if(localStorage.getItem('cookieDetail')){
                
                this.cookieComplianceValue = JSON.parse(localStorage.getItem('cookieDetail'))
                let temp =this.cookieComplianceValue.tracking.enabled
                this.cookieComplianceValue.tracking.enabled = temp
                console.log(temp)
            }
        },
        methods: {
            changeDisplay(val){
                if(this.cookieDisplay === val){
                    this.cookieDisplay = ''
                } else {
                    this.cookieDisplay = val;
                }
            },
            changeMainValue(){
                // console.log(this.cookieComplianceValue.tracking)
                // this.cookieComplianceValue.tracking.enabled = !this.cookieComplianceValue.tracking.enabled
                this.cookieComplianceValue.tracking.enabled = !this.cookieComplianceValue.tracking.enabled
                this.cookieComplianceValue.tracking.googleTagManager = this.cookieComplianceValue.tracking.enabled
                this.cookieComplianceValue.tracking.googleAnalytics = this.cookieComplianceValue.tracking.enabled

                // let tempBool = !this.w[val].enabled;
                // console.log(tempBool);
                // for (const [key] of Object.entries(this.cookieComplianceValue[val])) {
                    
                //     // if(key != 'enabled') {
                //     //     console.log(this.cookieComplianceValue[val][key])
                //         this.cookieComplianceValue[val][key] = tempBool
                //         console.log(key + ' hit')
                //         console.log(this.cookieComplianceValue[val][key])
                //     // } else {
                //     //     console.log(key + ' miss')
                //     // }
                // }
                console.log(this.cookieComplianceValue.tracking)
            },
            changeSubValue(categorie,val){
                this.cookieComplianceValue[categorie][val] = !this.cookieComplianceValue[categorie][val]
                console.log(val)
            },
            acceptAll(){
                let tempelement = {
                    essential:{
                        enabled:true
                    },
                    tracking:{
                        enabled:true,
                        googleTagManager:true,
                        googleAnalytics:true
                    }
                }
                localStorage.setItem('cookie',true)
                localStorage.setItem('cookieDetail',JSON.stringify(tempelement,true))
                this.bannerDisplay = false;               
                location.reload();
            },
            acceptChoosen(){
                localStorage.setItem('cookie',true);
                localStorage.setItem('cookieDetail',JSON.stringify(this.cookieComplianceValue,true))
                this.bannerDisplay = false;
                location.reload();
            }

        }
    })
</script>

<style>
    

/*-------------------------------------------------------*/
  .acris--page-wrap--cookie-permission {
    height: 100%;
    width: 100%;
    position: fixed;
    overflow-x: hidden;
    overflow-y: auto;
    background-color: rgba(0,0,0,0.52);
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: center;
    justify-content: center;
  }

  button#cookie-permission--accept-button {
    text-transform: uppercase;
    text-decoration: underline;
    background: unset;
    width: 100%;
    margin: 10px 0;
  }
  button#cookie-permission--accept-all-button {
    width: 100%;
    margin: 10px 0;
  }

  .cookie-permission--content a, .cookie-permission--footer a {
    font-size: 1em;
  }
  .sentry-error-embed-wrapper {
    z-index: 9999 !important
  }

  .promotion--show {
    height: inherit !important
  }

  .promotion-handle-rotate {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
    display: inline-block
  }

  .promotion-message-spacer {
    margin: .625rem
  }

  .promotion--outer {
    height: 1.875rem;
    padding: .625rem .125rem 0rem .125rem;
    width: 100%;
    bottom: 0;
    left: 0;
    position: absolute;
    overflow: hidden;
    z-index: 800;
    -webkit-transition: height .8s ease;
    -moz-transition: height .8s ease;
    -o-transition: height .8s ease;
    transition: height .8s ease
  }

  .promotion--inner {
    background-color: #f8f8fa;
    width: 100%;
    height: 100%;
    overflow: hidden;
    border: 1px solid #dadae5
  }

  .promotion-handle {
    line-height: .375rem;
    height: 1.25rem;
    width: 5rem;
    border-top-left-radius: 30px;
    border-top-right-radius: 30px;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
    background-clip: padding-box;
    top: .0625rem;
    font-size: 1.0625rem;
    padding: .0625rem;
    position: relative;
    float: right;
    cursor: pointer;
    text-align: center;
    border: 1px solid #dadae5;
    border-bottom-color: #f8f8fa;
    background-color: #f8f8fa
  }

  .free_goods-product,
  .container--product {
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
    border-bottom-left-radius: 3px;
    background-clip: padding-box;
    margin: .3125rem;
    border: 1px solid #d3d3d3;
    position: relative
  }

  .free_goods-product .nav {
    padding: .75rem;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
    border-bottom-left-radius: 3px;
    background-clip: padding-box;
    top: 7.5rem;
    border: 1px solid #d3d3d3;
    position: absolute;
    cursor: pointer;
    background: white
  }

  .free_goods-product .free_goods-product--toLeft {
    border-left: 0;
    left: 0
  }

  .free_goods-product .free_goods-product--toRight {
    right: 0;
    border-right: 0
  }

  .free_goods-product .free_goods-product--toRight:after {
    content: "";
    display: table;
    clear: both
  }

  .free_goods-product .free_goods-product--content .product-slider--container .container--product {
    border: 0
  }

  .free_goods-product .free_goods-product--content .product-slider--container .container--product .free_goods-product--variant .js--fancy-select {
    border-top-left-radius: 3px;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    border-bottom-left-radius: 3px;
    background-clip: padding-box;
    width: 85%;
    float: left;
    border-right: 0
  }

  .free_goods-product .free_goods-product--content .product-slider--container .container--product .product--inner .item--title {
    padding: .9375rem .3125rem .9375rem .3125rem;
    text-align: center;
    overflow: hidden
  }

  .free_goods-product .free_goods-product--content .product-slider--container .container--product .product--inner img {
    margin-bottom: .625rem;
    height: 8.75rem;
    margin: 0 auto
  }

  .free_goods-product .free_goods-product--content .product-slider--container .container--product .product--inner .hundred-percent {
    width: 100%
  }

  .free_goods-product .free_goods-product--content .product-slider--container .container--product .product--inner .ten-percent {
    width: 15%;
    border-top-left-radius: 0px;
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
    border-bottom-left-radius: 0px;
    background-clip: padding-box
  }

  .free_goods-product .free_goods-product--content .product-slider--container .container--product .product--inner .btn {
    height: 2.625rem
  }

  .promotion--free-goods-block {
    padding: 0rem 1.25rem 1.25rem 1.25rem
  }

  .promotion--free-goods-image {
    width: 5rem;
    height: 3rem;
    float: left
  }

  .promotion--badge {
    line-height: 3rem;
    font-size: 1rem;
    border-radius: 128px;
    background-clip: padding-box;
    width: 3rem;
    margin: 0 auto;
    text-align: center;
    font-weight: bold;
    background: #5f7285;
    color: #ffffff
  }

  .promotion--free-goods-hint-box {
    margin-left: 5rem
  }

  .promotion--free-goods-hint {
    float: left;
    width: 100%
  }

  .promotion--free-goods-hint-inner {
    margin-left: .9375rem;
    line-height: 1.1875rem;
    font-weight: bold
  }

  .promotion--link-to-free-goods {
    float: left;
    width: 100%
  }

  .promotion--link-to-free-goods-inner {
    margin-left: .9375rem;
    margin-top: .9375rem;
    line-height: 1.1875rem;
    font-weight: bold
  }

  .promotion--link-to-free-goods-inner .icon--arrow-right {
    font-size: .625rem;
    margin-left: .3125rem
  }

  @media screen and (min-width: 48em) {
    .promotion--free-goods-hint {
      width: 75%
    }
    .promotion--free-goods-hint-inner {
      line-height: 3rem
    }
    .promotion--link-to-free-goods {
      float: right;
      width: 25%
    }
    .promotion--link-to-free-goods-inner {
      line-height: 3rem;
      margin-left: 0rem;
      margin-top: 0rem
    }
  }

  .promotion--description-box {
    cursor: pointer
  }

  .promotion--description {
    width: 90%
  }

  .promotion--description p:last-of-type {
    display: inline
  }

  .promotion--index {
    margin-left: .3125rem
  }

  .promotion--is-right {
    right: 1.25rem;
    position: absolute;
    top: 50%;
    transform: translateY(-50%)
  }

  .promotion--detail-modal .header {
    padding-left: 2.5rem;
    padding-right: 2.5rem
  }

  .promotion--detail-modal .content {
    padding: 1.25rem 2.5rem 1.25rem 2.5rem
  }

  .promotion--detail-offcanvas {
    display: none
  }

  .promotion--detail-offcanvas .is--open {
    display: block
  }

  .alert.is--promotion {
    color: #208e4e;
    background: #def2e7
  }

  .alert.is--promotion .alert--icon {
    background: #2ecc71
  }

  .promotion--footnote-index {
    color: #208e4e
  }

  .promotion--content-description {
    padding: .625rem .625rem .625rem .625rem
  }

  .promotion--content-title {
    font-size: 1.125rem;
    font-weight: 700
  }

  .container--ajax-cart .free_goods-product--content .product-slider--item {
    width: 100%
  }

  .acris--page-wrap--cookie-permission {
    padding: .9375rem .625rem .9375rem .625rem;
    z-index: 90000;
    border-top: 1px solid #dadae5;
    /* background: #fff; */
    position: fixed;
    bottom: 0;
    right: 0;
    left: 0
  }

  .acris--page-wrap--cookie-permission.is--top-banner,
  .acris--page-wrap--cookie-permission.is--top-banner-slim {
    bottom: auto;
    top: 0
  }

  .acris--page-wrap--cookie-permission.is--top-banner-slim,
  .acris--page-wrap--cookie-permission.is--default-slim {
    padding: .3125rem .9375rem .3125rem .9375rem
  }

  .acris--page-wrap--cookie-permission.is--top-banner-slim .acris-cookie-settings--content,
  .acris--page-wrap--cookie-permission.is--default-slim .acris-cookie-settings--content {
    padding-bottom: .3125rem
  }

  .acris--page-wrap--cookie-permission.is--top-banner-slim .cookie-permission--container .cookie-permission--button,
  .acris--page-wrap--cookie-permission.is--default-slim .cookie-permission--container .cookie-permission--button {
    margin-top: 0
  }

  .acris--page-wrap--cookie-permission .cookie-permission--container {
    margin-top: .625rem;
    width: 100%;
    text-align: center
  }

  .acris--page-wrap--cookie-permission .cookie-permission--container:after {
    content: "";
    display: table;
    clear: both
  }

  .acris--page-wrap--cookie-permission .cookie-permission--container .cookie-permission--button {
    /* margin: 0 auto; */
    margin-top: .625rem
  }

  /* .acris--page-wrap--cookie-permission .cookie-permission--container.has--third-btn .cookie-permission--content {
    float: none;
    padding: .3125rem 0rem 0rem 0rem;
  } */

  .acris--page-wrap--cookie-permission .cookie-permission--container.has--third-btn .cookie-permission--button {
    float: none;
  }

  .acris--page-wrap--cookie-permission .cookie-permission--container.has--third-btn .cookie-permission--accept-only-functional-button {
    margin-right: .625rem;
  }

  .acris--page-wrap--cookie-permission .cookie-permission--container.has--accept-all-btn .cookie-permission--accept-button {
    margin-right: .625rem;
  }

  .acris--page-wrap--cookie-permission .cookie-permission--button .cookie-permission--button--button {
    margin-top: .3125rem;
  }

  .acris--page-wrap--cookie-permission .cookie-permission--heading .cookie-permission--heading--text {
    margin-bottom: .9375rem;
    font-weight: bold;
    font-size: 1.25rem;
  }

  .acris--page-wrap--cookie-permission .cookie-permission--footer {
    padding: .9375rem 0rem .3125rem 0rem;
    clear: both
  }

  .acris--page-wrap--cookie-permission.is--modal {
    box-shadow: 0px 0px 0px 7px rgba(0, 0, 0, 0.25);
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    /* width: 50rem; */
    margin: auto;
    padding: 0;
    max-width: 100%;
    max-height: 100%;
    right: 0;
    left: 0;
    top: 50%;
    bottom: auto;
    z-index: 90000;
    border: none;
    overflow-x: hidden;
    overflow-y: auto
  }

  .acris--page-wrap--cookie-permission.is--modal .acris-cookie-settings--wrapper {
    padding: .9375rem .625rem .9375rem .625rem;
    background-color: #efeae3;
    max-width: 900px;
  }

  .acris--page-wrap--cookie-permission.is--modal .cookie-permission--container .cookie-permission--content {
    padding: 0.3125rem 0.9rem 0.9375rem 0.9rem;
    float: none
  }

  .acris--page-wrap--cookie-permission.is--modal .cookie-permission--container .cookie-permission--button {
    float: none
  }

  .acris--page-wrap--cookie-permission .cookie-permission--container .cookie-permission--button .cookie-permission--deny-button {
    display: none;
  }

  @media screen and (min-width: 568px) {
    .cookie-permission--button {
      margin: 0 30%;
    }
  }

  @media screen and (min-width: 768px) {
    .acris--page-wrap--cookie-permission .acris-cookie-settings--content .cookie-setting--panel {
      margin: 0 25%;
    }
  }

  @media screen and (min-width: 48em) {
    .acris--page-wrap--cookie-permission.is--modal {
      border-radius: 3px;
      background-clip: padding-box
    }
  }

  @media screen and (min-width: 64em) {
    .acris--page-wrap--cookie-permission .cookie-permission--container {
      width: 100%
    }
    .acris--page-wrap--cookie-permission .cookie-permission--container:after {
      content: "";
      display: table;
      clear: both
    }
    .acris--page-wrap--cookie-permission .cookie-permission--container .cookie-permission--content {
      padding: .75rem 0rem .75rem 0rem;
      float: left
    }
    .acris--page-wrap--cookie-permission .cookie-permission--container .cookie-permission--button {
      /* margin: 0; */
      float: right
    }
    /* .acris--page-wrap--cookie-permission .cookie-permission--container .cookie-permission--button .cookie-permission--deny-button {
      margin-right: .625rem;
      display: none;
    } */
    .acris--page-wrap--cookie-permission .cookie-permission--container .cookie-permission--button .cookie-permission--button--button {
      margin-top: 0rem
    }
    /* .acris--page-wrap--cookie-permission .cookie-permission--container.has--third-btn .cookie-permission--content {
      padding: .3125rem 0rem .9375rem 0rem
    } */
    /* .acris--page-wrap--cookie-permission.is--modal .cookie-permission--container .cookie-permission--content {
      padding: .3125rem 0rem .9375rem 0rem
    } */
    .acris--page-wrap--cookie-permission.is--modal .cookie-permission--button .cookie-permission--button--button {
      margin-top: .3125rem
    }
  }

  .acris--page-wrap--cookie-permission .acris-cookie-settings--container {
    -webkit-transition: height .4s ease;
    transition: height .4s ease;
    max-height: 18.75rem;
    overflow: auto;
    display: none
  }

  .acris--page-wrap--cookie-permission .acris-cookie-settings--container.is--active {
    height: auto;
    display: inherit
  }

  .acris--page-wrap--cookie-permission .acris-cookie-settings--content {
    height: 100%
  }

  .acris--page-wrap--cookie-permission .acris-cookie-settings--content .acris-cookie-settings--groups {
    border-bottom: 1px solid #B9AA9E;
    height: 100%;
    overflow: auto
  }

  .acris--page-wrap--cookie-permission .acris-cookie-settings--content .cookie-setting--group {
    margin-bottom: .625rem
  }

  .acris--page-wrap--cookie-permission .acris-cookie-settings--content .cookie-setting--panel {
    border-radius: 3px;
    background-clip: padding-box;
    padding: .9375rem .9375rem .9375rem .9375rem;
    /* background-color: #f5f5f8 */
  }

  .acris--page-wrap--cookie-permission .acris-cookie-settings--content .cookie-setting--panel:after {
    content: "";
    display: table;
    clear: both
  }

  .acris--page-wrap--cookie-permission .acris-cookie-settings--content .cookie-setting--title {
    -webkit-transition: color .3s ease;
    transition: color .3s ease;
    max-width: calc(100% - 60px);
    font-weight: 700;
    float: left;
    cursor: pointer
  }

  .acris--page-wrap--cookie-permission .acris-cookie-settings--content .title--icon .icon--arrow-down {
    width: 0;
    height: 0;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-top: 18px solid #000000;
  }

  .acris--page-wrap--cookie-permission .acris-cookie-settings--content .cookie-setting--title:hover .title--icon .icon--arrow-down {
    border-top: 18px solid #D35316;
  }

  .acris--page-wrap--cookie-permission .acris-cookie-settings--content .cookie-setting--title:hover {
    color: #D35316;
  }

  .acris--page-wrap--cookie-permission .acris-cookie-settings--content .cookie-setting--title:hover .title--icon {
    /* background-color: #d9400b */
  }

  .acris--page-wrap--cookie-permission .acris-cookie-settings--content .cookie-setting--title.is--active .title--icon {
    -webkit-transform: rotate(-180deg);
    transform: rotate(-180deg);
  }

  .acris--page-wrap--cookie-permission .acris-cookie-settings--content .cookie-setting--title .title--name {
    margin-right: .3125rem;
    line-height: 1.25rem;
    display: inline-block;
    vertical-align: middle
  }

  .acris--page-wrap--cookie-permission .acris-cookie-settings--content .cookie-setting--title .title--icon {
    -webkit-transition: all .3s ease;
    transition: all .3s ease;
    border-radius: 50%;
    background-clip: padding-box;
    width: 1.25rem;
    height: 1.25rem;
    line-height: 1.25rem;
    font-size: .25rem;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    /* background-color: #5f7285; */
    color: #fff;
    text-align: center
  }

  .acris--page-wrap--cookie-permission .acris-cookie-settings--content .cookie-setting--title .title--icon i,
  .acris--page-wrap--cookie-permission .acris-cookie-settings--content .cookie-setting--title .title--icon i::before {
    font-size: 4px
  }

  .acris--page-wrap--cookie-permission .acris-cookie-settings--content .cookie-setting--switch--container {
    float: right;
    width: 15%
  }

  .acris--page-wrap--cookie-permission .acris-cookie-settings--content .cookie-setting--switch--container .switch--slider--enabled {
    margin-right: .625rem
  }

  .acris--page-wrap--cookie-permission .acris-cookie-settings--content .cookie-setting--switch--container .switch--slider--enabled+.cookie-setting--switch:has(input:checked) {
    display: none
  }

  .acris--page-wrap--cookie-permission .acris-cookie-settings--content .cookie-setting--switch--container .cookie-setting--switch {
    width: 2.75rem;
    height: 1.25rem;
    position: relative;
    display: inline-block;
    float: right
  }

  .acris--page-wrap--cookie-permission .acris-cookie-settings--content .cookie-setting--switch--container .cookie-setting--switch input {
    opacity: 0;
    width: 0;
    height: 0
  }

  .acris--page-wrap--cookie-permission .acris-cookie-settings--content .cookie-setting--switch--container .cookie-setting--switch .cookie-setting--switch--group {
    float: right
  }

  .acris--page-wrap--cookie-permission .acris-cookie-settings--content .cookie-setting--switch--container .cookie-setting--switch .switch--slider {
    -webkit-transition: all .4s ease;
    transition: all .4s ease;
    border-radius: 20px;
    background-clip: padding-box;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    cursor: pointer;
    background-color: #c8c4c2;
  }

  .acris--page-wrap--cookie-permission .acris-cookie-settings--content .cookie-setting--switch--container .cookie-setting--switch .switch--slider:before {
    -webkit-transition: all .4s ease;
    transition: all .4s ease;
    border-radius: 50%;
    background-clip: padding-box;
    width: .875rem;
    height: .875rem;
    left: .1875rem;
    bottom: .1875rem;
    position: absolute;
    content: "";
    background-color: #737373;
  }

  .acris--page-wrap--cookie-permission .acris-cookie-settings--content .cookie-setting--switch--container .cookie-setting--switch .switch--slider--enabled {
    position: absolute;
    right: 3.75rem;
    display: block;
    width: 100%;
    text-align: right
  }

  .acris--page-wrap--cookie-permission .acris-cookie-settings--content .cookie-setting--switch--container .cookie-setting--switch .switch--slider--enabled.switch--slider--active {
    display: none
  }

  .acris--page-wrap--cookie-permission .acris-cookie-settings--content .cookie-setting--switch--container .cookie-setting--switch input:checked+.switch--slider {
    background-color: #b9aa9f;
  }

  .acris--page-wrap--cookie-permission .acris-cookie-settings--content .cookie-setting--switch--container .cookie-setting--switch input:checked~.switch--slider--active {
    display: block
  }

  .acris--page-wrap--cookie-permission .acris-cookie-settings--content .cookie-setting--switch--container .cookie-setting--switch input:checked~.switch--slider--inactive {
    display: none
  }

  .acris--page-wrap--cookie-permission .acris-cookie-settings--content .cookie-setting--switch--container .cookie-setting--switch input:focus+.switch--slider {
    box-shadow: 0 0 1px #d9400b
  }

  .acris--page-wrap--cookie-permission .acris-cookie-settings--content .cookie-setting--switch--container .cookie-setting--switch input:checked+.switch--slider:before {
    -webkit-transform: translateX(24px);
    transform: translateX(24px);
    background: #D35316;
  }

  .acris--page-wrap--cookie-permission .acris-cookie-settings--content .cookie-setting--switch--container .cookie-setting--switch.is--default .switch--slider,
  .acris--page-wrap--cookie-permission .acris-cookie-settings--content .cookie-setting--switch--container .cookie-setting--switch.is--disabled .switch--slider {
    cursor: not-allowed;
    opacity: 0.5
  }

  .acris--page-wrap--cookie-permission .acris-cookie-settings--content .cookie-setting--description {
    margin-bottom: .75rem;
    padding-bottom: .75rem;
    border-bottom: 1px solid #B9AA9E;
  }

  .acris--page-wrap--cookie-permission .acris-cookie-settings--content .cookie-setting--data {
    display: none
  }

  .acris--page-wrap--cookie-permission .acris-cookie-settings--content .cookie-setting--data .cookie-setting--data--inner {
    padding: .9375rem .9375rem .625rem .9375rem
  }

  .acris--page-wrap--cookie-permission .acris-cookie-settings--content .cookie-setting--data .cookie--item {
    margin-bottom: 2.8125rem
  }

  .acris--page-wrap--cookie-permission .acris-cookie-settings--content .cookie-setting--data .cookie--title {
    font-weight: 700;
    padding-right: .3125rem;
    text-overflow: ellipsis;
    overflow: hidden
  }

  .acris--page-wrap--cookie-permission.is--modal .acris-cookie-settings--content .cookie-setting--switch--container {
    width: 15%
  }

  .acris--page-wrap--cookie-permission.is--inactive,
  #acris--cookie-permission--modal-overlay.is--inactive {
    display: none
  }

  @media screen and (min-width: 48em) {
    .acris--page-wrap--cookie-permission .acris-cookie-settings--content .cookie-setting--cookies {
      display: block
    }
    .acris--page-wrap--cookie-permission .acris-cookie-settings--content .cookie-setting--cookies .cookie--item {
      margin-bottom: .625rem
    }
    .acris--page-wrap--cookie-permission .acris-cookie-settings--content .cookie-setting--cookies .cookie--item:after {
      content: "";
      display: table;
      clear: both
    }
    .acris--page-wrap--cookie-permission .acris-cookie-settings--content .cookie-setting--cookies .cookie--title {
      display: block;
      width: 20%;
      float: left
    }
    .acris--page-wrap--cookie-permission .acris-cookie-settings--content .cookie-setting--cookies .cookie--description {
      display: block;
      width: 64%;
      float: left
    }
    .acris--page-wrap--cookie-permission .acris-cookie-settings--content .cookie-setting--cookies .cookie-setting--switch--container {
      width: 15%
    }
  }

  @media screen and (min-width: 78.75em) {
    .acris--page-wrap--cookie-permission .acris-cookie-settings--container {
      max-height: 25rem
    }
    .acris--page-wrap--cookie-permission .acris-cookie-settings--content .cookie-setting--cookies .cookie--title {
      width: 15%
    }
    .acris--page-wrap--cookie-permission .acris-cookie-settings--content .cookie-setting--cookies .cookie--description {
      width: 74%
    }
    .acris--page-wrap--cookie-permission .acris-cookie-settings--content .cookie-setting--cookies .cookie-setting--switch--container {
      width: 10%
    }
    .acris--page-wrap--cookie-permission.is--modal .acris-cookie-settings--content .cookie-setting--cookies .cookie--title {
      width: 20%
    }
    .acris--page-wrap--cookie-permission.is--modal .acris-cookie-settings--content .cookie-setting--cookies .cookie--description {
      width: 64%
    }
    .acris--page-wrap--cookie-permission.is--modal .acris-cookie-settings--content .cookie-setting--cookies .cookie-setting--switch--container {
      width: 15%
    }
  }

  /* @font-face {
    font-family: "Helvetica Neue Condensed";
    font-weight: 700;
    src: url(https://cdn.migros.ch/ch.migros/static/fonts/helveticaneue-condensed-bold/helveticaneue-condensed-bold.eot?#iefix);
    src: url(https://cdn.migros.ch/ch.migros/static/fonts/helveticaneue-condensed-bold/helveticaneue-condensed-bold.eot?#iefix) format("embedded-opentype"), url(https://cdn.migros.ch/ch.migros/static/fonts/helveticaneue-condensed-bold/helveticaneue-condensed-bold.woff) format("woff"), url(https://cdn.migros.ch/ch.migros/static/fonts/helveticaneue-condensed-bold/helveticaneue-condensed-bold.ttf) format("truetype"), url(https://cdn.migros.ch/ch.migros/static/fonts/helveticaneue-condensed-bold/helveticaneue-condensed-bold.svg#9d3bb5f2-6932-419e-a56f-382131ffd4b8) format("svg")
  } */

  .a-service-login {
    text-decoration: none;
    box-sizing: border-box
  }

  .a-service-login {
    -ms-flex-item-align: center;
    align-self: center;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 3px;
    color: #333;
    display: inline-block;
    font-size: 12px;
    height: 22px;
    line-height: 22px;
    padding-left: 35px;
    position: relative;
    text-align: left;
    font-family: "Helvetica Neue Condensed", Helvetica, Arial;
    text-transform: uppercase
  }

  .a-service-login:before {
    /* background: transparent url(../../custom/plugins/NetaAuthExtended/Resources/views/frontend/_public/images/m.svg) no-repeat 8px 2px; */
    background-size: 11px auto;
    border-right: 1px solid #ccc;
    content: '';
    height: 12px;
    left: 0;
    position: absolute;
    top: 4px;
    width: 27px
  }

  .a-service-login:hover,
  .a-service-login:active {
    background-color: #f7f7f7;
    color: #333
  }

  .a-service-login:focus {
    color: #333
  }

  .a-service-login--big {
    height: 40px;
    line-height: 40px;
    font-size: 18px;
    padding-left: 48px
  }

  .a-service-login--big:before {
    /* background: transparent url(../../custom/plugins/NetaAuthExtended/Resources/views/frontend/_public/images/m.svg) no-repeat 12px 3px; */
    background-size: 15px auto;
    height: 22px;
    width: 38px;
    top: 10px
  }

  .a-service-login--dark {
    border-color: transparent
  }

  .a-service-login--disabled {
    color: #adadad
  }

  .a-service-login--disabled:hover,
  .a-service-login--disabled:active {
    background-color: #fff;
    color: #adadad
  }

  .a-service-login--lang-de {
    width: 102px
  }

  .a-service-login--lang-de.a-service-login--big {
    width: 145px
  }

  .a-service-login--lang-fr {
    width: 114px
  }

  .a-service-login--lang-fr.a-service-login--big {
    width: 169px
  }

  .a-service-login--lang-it {
    width: 80px
  }

  .a-service-login--lang-it.a-service-login--big {
    width: 115px
  }

  .a-service-login--lang-en {
    width: 90px
  }

  .a-service-login--lang-en.a-service-login--big {
    width: 128px
  }

  @-webkit-keyframes keyframe--spin {
    from {
      -webkit-transform: rotate(0);
      transform: rotate(0)
    }
    to {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg)
    }
  }

  @keyframes keyframe--spin {
    from {
      -webkit-transform: rotate(0);
      transform: rotate(0)
    }
    to {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg)
    }
  }

  @-webkit-keyframes keyframe--spin-reverse {
    from {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg)
    }
    to {
      -webkit-transform: rotate(0);
      transform: rotate(0)
    }
  }

  @keyframes keyframe--spin-reverse {
    from {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg)
    }
    to {
      -webkit-transform: rotate(0);
      transform: rotate(0)
    }
  }

  .visible--s,
  .visible-inline--s,
  .visible-inline-block--s,
  .visible--m,
  .visible-inline--m,
  .visible-inline-block--m,
  .visible--l,
  .visible-inline--l,
  .visible-inline-block--l,
  .visible--xl,
  .visible-inline--xl,
  .visible-inline-block--xl {
    display: none !important
  }

  .visible--xs {
    display: block !important
  }

  table.visible--xs {
    display: table !important
  }

  tr.visible--xs {
    display: table-row !important
  }

  th.visible--xs,
  td.visible--xs {
    display: table-cell !important
  }

  .visible-inline--xs {
    display: inline !important
  }

  table.visible-inline--xs {
    display: table !important
  }

  tr.visible-inline--xs {
    display: table-row !important
  }

  th.visible-inline--xs,
  td.visible-inline--xs {
    display: table-cell !important
  }

  .visible-inline-block--xs {
    display: inline-block !important
  }

  table.visible-inline-block--xs {
    display: table !important
  }

  tr.visible-inline-block--xs {
    display: table-row !important
  }

  th.visible-inline-block--xs,
  td.visible-inline-block--xs {
    display: table-cell !important
  }

  .hidden--s,
  .hidden--m,
  .hidden--l,
  .hidden--xl {
    display: inherit !important
  }

  .hidden--xs {
    display: none !important
  }

  @media screen and (min-width: 30em) {
    .visible--xs,
    .visible-inline--xs,
    .visible-inline-block--xs,
    .visible--m,
    .visible-inline--m,
    .visible-inline-block--m,
    .visible--l,
    .visible-inline--l,
    .visible-inline-block--l,
    .visible--xl,
    .visible-inline--xl,
    .visible-inline-block--xl {
      display: none !important
    }
    .visible--s {
      display: block !important
    }
    table.visible--s {
      display: table !important
    }
    tr.visible--s {
      display: table-row !important
    }
    th.visible--s,
    td.visible--s {
      display: table-cell !important
    }
    .visible-inline--s {
      display: inline !important
    }
    table.visible-inline--s {
      display: table !important
    }
    tr.visible-inline--s {
      display: table-row !important
    }
    th.visible-inline--s,
    td.visible-inline--s {
      display: table-cell !important
    }
    .visible-inline-block--s {
      display: inline-block !important
    }
    table.visible-inline-block--s {
      display: table !important
    }
    tr.visible-inline-block--s {
      display: table-row !important
    }
    th.visible-inline-block--s,
    td.visible-inline-block--s {
      display: table-cell !important
    }
    .hidden--xs,
    .hidden--m,
    .hidden--l,
    .hidden--xl {
      display: inherit !important
    }
    .hidden--s {
      display: none !important
    }
  }

  @media screen and (min-width: 48em) {
    .visible--xs,
    .visible-inline--xs,
    .visible-inline-block--xs,
    .visible--s,
    .visible-inline--s,
    .visible-inline-block--s,
    .visible--l,
    .visible-inline--l,
    .visible-inline-block--l,
    .visible--xl,
    .visible-inline--xl,
    .visible-inline-block--xl {
      display: none !important
    }
    .visible--m {
      display: block !important
    }
    table.visible--m {
      display: table !important
    }
    tr.visible--m {
      display: table-row !important
    }
    th.visible--m,
    td.visible--m {
      display: table-cell !important
    }
    .visible-inline--m {
      display: inline !important
    }
    table.visible-inline--m {
      display: table !important
    }
    tr.visible-inline--m {
      display: table-row !important
    }
    th.visible-inline--m,
    td.visible-inline--m {
      display: table-cell !important
    }
    .visible-inline-block--m {
      display: inline-block !important
    }
    table.visible-inline-block--m {
      display: table !important
    }
    tr.visible-inline-block--m {
      display: table-row !important
    }
    th.visible-inline-block--m,
    td.visible-inline-block--m {
      display: table-cell !important
    }
    .hidden--xs,
    .hidden--s,
    .hidden--l,
    .hidden--xl {
      display: inherit !important
    }
    .hidden--m {
      display: none !important
    }
  }

  @media screen and (min-width: 64em) {
    .visible--xs,
    .visible-inline--xs,
    .visible-inline-block--xs,
    .visible--s,
    .visible-inline--s,
    .visible-inline-block--s,
    .visible--m,
    .visible-inline--m,
    .visible-inline-block--m,
    .visible--xl,
    .visible-inline--xl,
    .visible-inline-block--xl {
      display: none !important
    }
    .visible--l {
      display: block !important
    }
    table.visible--l {
      display: table !important
    }
    tr.visible--l {
      display: table-row !important
    }
    th.visible--l,
    td.visible--l {
      display: table-cell !important
    }
    .visible-inline--l {
      display: inline !important
    }
    table.visible-inline--l {
      display: table !important
    }
    tr.visible-inline--l {
      display: table-row !important
    }
    th.visible-inline--l,
    td.visible-inline--l {
      display: table-cell !important
    }
    .visible-inline-block--l {
      display: inline-block !important
    }
    table.visible-inline-block--l {
      display: table !important
    }
    tr.visible-inline-block--l {
      display: table-row !important
    }
    th.visible-inline-block--l,
    td.visible-inline-block--l {
      display: table-cell !important
    }
    .hidden--xs,
    .hidden--s,
    .hidden--m,
    .hidden--xl {
      display: inherit !important
    }
    .hidden--l {
      display: none !important
    }
  }

  @media screen and (min-width: 78.75em) {
    .visible--xs,
    .visible-inline--xs,
    .visible-inline-block--xs,
    .visible--s,
    .visible-inline--s,
    .visible-inline-block--s,
    .visible--m,
    .visible-inline--m,
    .visible-inline-block--m,
    .visible--l,
    .visible-inline--l,
    .visible-inline-block--l {
      display: none !important
    }
    .visible--xl {
      display: block !important
    }
    table.visible--xl {
      display: table !important
    }
    tr.visible--xl {
      display: table-row !important
    }
    th.visible--xl,
    td.visible--xl {
      display: table-cell !important
    }
    .visible-inline--xl {
      display: inline !important
    }
    table.visible-inline--xl {
      display: table !important
    }
    tr.visible-inline--xl {
      display: table-row !important
    }
    th.visible-inline--xl,
    td.visible-inline--xl {
      display: table-cell !important
    }
    .visible-inline-block--xl {
      display: inline-block !important
    }
    table.visible-inline-block--xl {
      display: table !important
    }
    tr.visible-inline-block--xl {
      display: table-row !important
    }
    th.visible-inline-block--xl,
    td.visible-inline-block--xl {
      display: table-cell !important
    }
    .hidden--xs,
    .hidden--s,
    .hidden--m,
    .hidden--l {
      display: inherit !important
    }
    .hidden--xl {
      display: none !important
    }
  }
</style>
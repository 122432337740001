<template>
    
    <div class="navbar-top">
        <div class="locale-changer">
            <button type="submit" class="locale-change-item" :class="{'active': this.$i18n.locale == 'de'}" @click="switchLocale('de');">DE</button>
            <button type="submit" class="locale-change-item" :class="{'active': this.$i18n.locale == 'fr'}" @click="switchLocale('fr');">FR</button>
            <!-- <button type="submit" class="locale-change-item" :class="{'active': this.$i18n.locale == 'it'}" @click="switchLocale('it');">IT</button> -->
        </div>
    </div>
    
</template>

<script>
    export default {
        "name":"LocaleChanger",
        created: () => {
        
        },
        methods: {
            switchLocale(locale) {
                if (this.$i18n.locale !== locale) {
                    this.$i18n.locale = locale;
                    const to = this.$router.resolve({ params: {locale} })
                    this.$router.push(to.fullPath)
                }
            }
        },
        data() {
            return {
            locales: process.env.VUE_APP_I18N_SUPPORTED_LOCALE.split(',')
            }
        }
    }
</script>

<style lang="sass" scoped>

</style>